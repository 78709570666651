import {
  CameraIcon,
  CitiesIcon,
  CorporateClientsIcon,
  DriversApplicationIcon,
  DriversIcon,
  FraudIcon,
  HomeIcon,
  NewsIcon,
  PromotionsIcon,
  ReportIcon,
  RidersIcon,
  RidesIcon,
  SettingsIcon,
  StatisticsIcon,
  SupportIcon,
  TaxiCompanyApplicationIcon,
  TransactionIcon,
  UsersIcon,
} from "@assets/Icons";
import { NAVIGATE_ROUTES } from "@common/Constants";

export const sidebarRoutes = [
  {
    title: "СТРАНИЦЫ",
    routes: [
      {
        label: "Главная",
        path: NAVIGATE_ROUTES.DASHBOARD,
        icon: HomeIcon,
        renderKey: "main",
        rightComponent: false,
      },
      {
        label: "Заявки от водителей",
        path: NAVIGATE_ROUTES.APPLICATIONS,
        icon: DriversApplicationIcon,
        renderKey: "driver_application_main",
        rightComponent: true,
      },
      {
        label: "Заявки от таксопарков",
        path: NAVIGATE_ROUTES.PARKS_APPLICATIONS,
        icon: TaxiCompanyApplicationIcon,
        renderKey: "park_application_main",
        rightComponent: true,
      },
      {
        label: "Фотоконтроль",
        path: NAVIGATE_ROUTES.PHOTO_CONTROL,
        icon: CameraIcon,
        renderKey: "photo_control_main",
        rightComponent: false,
      },
      {
        label: "Города",
        path: NAVIGATE_ROUTES.CITIES,
        icon: CitiesIcon,
        renderKey: "cities_main",
        rightComponent: false,
      },
      {
        label: "Контроль фрода",
        path: NAVIGATE_ROUTES.FRAUD_CONTROL,
        icon: FraudIcon,
        renderKey: "fraud_control_main",
        rightComponent: true,
      },
    ],
  },
  {
    title: "ПРОФИЛИ",
    routes: [
      {
        label: (role: string) =>
          role === "parkadmin" ? "Мои поездки" : "Поездки",
        path: NAVIGATE_ROUTES.RIDES,
        icon: RidesIcon,
        renderKey: "rides_main",
        rightComponent: false,
      },
      {
        label: (role: string) =>
          role === "parkadmin" ? "Мои водители" : "Водители",
        path: NAVIGATE_ROUTES.DRIVERS,
        icon: DriversIcon,
        renderKey: "drivers_main",
        rightComponent: false,
      },
      {
        label: "Клиенты",
        path: NAVIGATE_ROUTES.RIDERS,
        icon: RidersIcon,
        renderKey: "riders_main",
        rightComponent: false,
      },
      {
        label: "Корпоративные клиенты",
        path: NAVIGATE_ROUTES.CORPORATE_CLIENTS,
        icon: CorporateClientsIcon,
        renderKey: "corporate_clients_main",
        rightComponent: false,
      },
      {
        label: "Таксопарки",
        path: NAVIGATE_ROUTES.PARKS,
        icon: TaxiCompanyApplicationIcon,
        renderKey: "parks_main",
        rightComponent: false,
      },
      {
        label: "Мой таксопарк",
        path: NAVIGATE_ROUTES.MY_PARK_DETAIL,
        icon: TaxiCompanyApplicationIcon,
        renderKey: "",
        rightComponent: false,
      },
      {
        label: "Электронный кошелёк и операции",
        icon: TransactionIcon,
        path: NAVIGATE_ROUTES.TRANSACTIONS,
        activePage: "transactions",
        renderKey: "transaction_main",
        rightComponent: false,
      },
      {
        label: "Новости",
        path: NAVIGATE_ROUTES.NEWS,
        icon: NewsIcon,
        renderKey: "news_main",
        rightComponent: false,
      },
      {
        label: "Акции",
        path: NAVIGATE_ROUTES.PROMOTIONS,
        icon: PromotionsIcon,
        renderKey: "promotion_main",
        rightComponent: false,
      },
    ],
  },
  {
    title: "СТАТИСТИКА",
    routes: [
      {
        label: "Статистика",
        path: NAVIGATE_ROUTES.STATISTICS,
        icon: StatisticsIcon,
        renderKey: "statistics_main",
        rightComponent: false,
      },
      {
        label: "Отчеты",
        path: NAVIGATE_ROUTES.REPORT,
        icon: ReportIcon,
        renderKey: "cbk_report",
        rightComponent: false,
      },
      {
        label: "Чат поддержки",
        path: NAVIGATE_ROUTES.CHAT,
        icon: SupportIcon,
        renderKey: "chat",
        rightComponent: false,
      },
      {
        label: "Настройки",
        path: NAVIGATE_ROUTES.SETTINGS,
        icon: SettingsIcon,
        renderKey: "settings_main",
        rightComponent: false,
      },
    ],
  },
  {
    title: "ПОЛЬЗОВАТЕЛИ",
    routes: [
      {
        label: "Пользователи",
        path: NAVIGATE_ROUTES.USERS,
        icon: UsersIcon,
        renderKey: "admins_main",
        rightComponent: false,
      },
    ],
  },
];
