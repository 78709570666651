import qs from "query-string";

import { httpClient } from "@common/Libs/axios";
import {
  AccountReportFormValues,
  AccountReportsType,
  DownloadReportParams,
  PartialFiltersType,
  PaymentReportType,
  ProviderType,
  QueryParams,
  RefundReportsType,
  ReportType,
  ReportTypeFilters,
} from "@common/Types";
import {
  buildCommonFiltersValues,
  buildDateRangeFiltersValues,
  commaSerializer,
} from "@common/Utils";

import { reportsPath } from "./path";

export const reportsService = {
  getReports: (filters: ReportTypeFilters) => {
    const { park_ids, ...rest } = filters;

    return httpClient.get<ReportType>(reportsPath.dashboard_reports, {
      params: {
        ...buildCommonFiltersValues(rest),
        park_ids,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "none" }),
    });
  },

  getPaymentReports: (filters: PartialFiltersType) =>
    httpClient.get<PaymentReportType>(reportsPath.reports_payment, {
      params: {
        ...buildCommonFiltersValues(filters),
      },
      paramsSerializer: commaSerializer,
    }),

  getPaymentProviders: () =>
    httpClient.get<ProviderType[]>(reportsPath.reports_payment_providers),

  getRefundReports: ({ skip, limit, filters }: QueryParams) =>
    httpClient.get<RefundReportsType>(reportsPath.reports_refund_history, {
      params: {
        ...buildCommonFiltersValues(filters),
        skip,
        limit,
      },
      paramsSerializer: commaSerializer,
    }),

  getAccountReports: ({ skip, limit, filters }: QueryParams) =>
    httpClient.get<AccountReportsType>(reportsPath.reports, {
      params: {
        ...buildDateRangeFiltersValues(filters),
        skip,
        limit,
      },
      paramsSerializer: commaSerializer,
    }),

  downloadAccountReport: ({ reportId, type }: DownloadReportParams) =>
    httpClient.get<{ url: string }>(
      reportsPath.reports_account_reports_download(reportId),
      {
        params: {
          report_file_type: type,
        },
      },
    ),

  createAccountReport: (report: AccountReportFormValues) =>
    httpClient.post(reportsPath.reports, report),
};
