import React from "react";

import { useGetPermissions } from "@api/queries";
import { ROLES } from "@common/Constants";
import { RoleType } from "@common/Types";
import { getUserRole } from "@common/Utils";

type Props = {
  roles: RoleType[];
  renderKey?: string;
  children?: React.ReactNode;
};

export const AuthRender = ({ children, roles, renderKey }: Props) => {
  const userRole = getUserRole() as RoleType;
  const isDispatcher = [ROLES.DISPATCHER].includes(userRole);
  const { response = [] } = useGetPermissions({
    enabled: !!renderKey && isDispatcher,
  });

  const isExistRole = roles.includes(userRole as RoleType);

  if (renderKey && isDispatcher) {
    const isKeyExist = response.find(({ name }) => renderKey === name);

    return isKeyExist?.is_active && isExistRole ? <>{children}</> : null;
  }

  return isExistRole ? <>{children}</> : null;
};
