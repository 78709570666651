import { Textarea as MantineTextArea, Tooltip } from "@mantine/core";
import { Field, useField } from "formik";
import { isEmpty } from "radash";
import React from "react";

import { TooltipIcon } from "@assets/Icons";

import { Row } from "../flexbox";
import { Typography } from "../typography";
import { TextAreaProps } from "./types";
import { VIEW_CLASSES } from "./variants";

/**
 * A customizable text area component built on top of Mantine's `Textarea`,
 * with additional Tailwind CSS styling and support for dynamic class merging.
 *
 * @param {Object} props - The properties object.
 * @returns {React.FC} A React functional component that renders a styled text area input.
 */

export const TextArea = ({
  view = "default",
  label,
  required,
  error,
  tooltip,
  minRows = 3,
  maxRows = 6,
  ...props
}: TextAreaProps) => {
  const {
    opened,
    label: tooltipLabel,
    children: tooltipChildren = <TooltipIcon />,
    ...tooltipProps
  } = tooltip ?? {};

  const isTooltipOpened = opened ?? !isEmpty(tooltip);

  return (
    <div className="w-full">
      <Row alignItems="items-center" className="inline-flex mb-1">
        <Typography className={VIEW_CLASSES[view].label} as="label">
          {label}

          {required && <span className="text-error ml-1">*</span>}
        </Typography>

        {isTooltipOpened && (
          <Tooltip label={tooltipLabel} className="ml-1" {...tooltipProps}>
            {tooltipChildren}
          </Tooltip>
        )}
      </Row>

      <MantineTextArea
        minRows={minRows}
        maxRows={maxRows}
        error={error}
        classNames={VIEW_CLASSES[error ? "error" : view]}
        {...props}
      />
    </div>
  );
};

export const FormTextArea = ({
  field,
  ...rest
}: TextAreaProps & { field: string }) => {
  const [fieldProps, { touched, error }] = useField(field);

  return (
    <Field as={TextArea} error={touched && error} {...fieldProps} {...rest} />
  );
};
