import { Form, Formik } from "formik";
import React from "react";

import { Button, FormTextArea, Modal, Typography } from "@components/Elements";
import { activationValidationSchema } from "@components/SharedFeature/drivers/common/validation";

type Props = {
  title: string;
  buttonText: string;
  contentText?: string;
  isReasonRequired?: boolean;
  open: boolean;
  onSubmit: (comment: string) => void;
  onClose: () => void;
};

const initialFormValues = {
  comment: "",
};

export const ConfirmModalWithReason: React.FC<Props> = ({
  open,
  title,
  buttonText,
  contentText,
  onClose,
  onSubmit,
  isReasonRequired = false,
}) => {
  return (
    <Modal title={title} opened={open} onClose={onClose}>
      <Formik
        initialValues={initialFormValues}
        validationSchema={activationValidationSchema(isReasonRequired)}
        onSubmit={({ comment }) => onSubmit(comment)}
      >
        {({ isSubmitting }) => (
          <Form className="pt-6">
            <div className="space-y-6">
              <div className="space-y-4">
                {contentText && (
                  <Typography variant="body-2-a" color="neutral-800" as="p">
                    {contentText}
                  </Typography>
                )}

                <FormTextArea
                  field="comment"
                  required
                  label="Причина"
                  placeholder="Напишите причину"
                  minRows={2}
                />
              </div>

              <Button type="submit" size="lg" fullWidth loading={isSubmitting}>
                {buttonText}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
