import React from "react";
import { HelmetProvider } from "react-helmet-async";

import { FeatureFlagsProvider, SentryProvider } from "@common/Providers";

import { DashboardProvidersLayout } from "./dashboard-providers-layout";
import { GoogleAnalyticsProvider } from "./GAProvider";

type Props = {
  children: React.ReactNode;
};

export const AppProvidersLayout: React.FC<Props> = ({ children }) => {
  return (
    <SentryProvider>
      <HelmetProvider>
        <GoogleAnalyticsProvider>
          <FeatureFlagsProvider>
            <DashboardProvidersLayout>{children}</DashboardProvidersLayout>
          </FeatureFlagsProvider>
        </GoogleAnalyticsProvider>
      </HelmetProvider>
    </SentryProvider>
  );
};
