import clsx from "clsx";
import { motion } from "framer-motion";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { NAVIGATE_ROUTES } from "@common/Constants";
import { useDimension, useOnClickOutside } from "@common/Hooks";
import { LanguageSwitcher } from "@components/Shared";

import { AppLandingLogo, BurgerMenu, CloseMenu } from "../../../../assets/Icons/landing";

const mobileNavFramer = {
  open: {
    x: "0%",
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.09,
      type: "spring",
      damping: 70,
      stiffness: 400,
    },
  },
  closed: { x: "-321px" },
};

const itemsFramer = {
  open: { opacity: 1, y: 0 },
  closed: { opacity: 0, y: 10 },
};

type NavLinkProps = {
  url: string;
  children: React.ReactNode;
  isHighlight?: boolean;
};

const NavLink = ({ url, children, isHighlight }: NavLinkProps) => {
  return (
    <Link
      to={url}
      className={clsx(
        "block md:flex items-center justify-center h-[38px] px-[24px] rounded-[8px] text-[16px] capitalize font-bold bg-[#2F3437] text-white transition-transform transform hover:-translate-y-1",
        {
          "bg-gradient-to-b to-[#0CC1DA] from-[#53D6E8] !text-[#000]":
            isHighlight,
        },
      )}
    >
      {children}
    </Link>
  );
};

type MobileMenuProps = {
  isOpen: boolean;
  onClose: () => void;
};

const MobileMenu = ({ isOpen, onClose }: MobileMenuProps) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLElement>(null);

  useOnClickOutside(ref, onClose);

  return (
    <motion.nav
      ref={ref}
      transition={{
        type: "spring",
        damping: 70,
        stiffness: 400,
      }}
      className={
        "fixed flex flex-col inset-0 h-screen text-white bg-dark-500 z-50 px-[24px] w-full max-w-[320px]"
      }
      style={{ willChange: "transform" }}
      animate={isOpen ? "open" : "closed"}
      initial={{ x: "-321px" }}
      variants={mobileNavFramer}
    >
      <div className={"flex flex-col w-full h-svh"}>
        <div className={"flex items-center py-[14px] mb-[20px]"}>
          <Link
            to={NAVIGATE_ROUTES.MAIN}
            className="text-xl text-white font-bold font-heading uppercase flex w-max z-60"
            aria-label={"go to main page"}
          >
            <AppLandingLogo className={"w-auto h-[24px]"} />
          </Link>
          <div className={"absolute right-[12px]"}>
            <button
              onClick={onClose}
              aria-label={"close mobile menu"}
              className={"flex items-center"}
            >
              <CloseMenu />
            </button>
          </div>
        </div>
        <div className={"flex flex-col w-full gap-[16px] mt-[15vh]"}>
          <motion.span
            variants={itemsFramer}
            className={"text-dark-300 text-sm"}
          >
            {t("menu")}
          </motion.span>
          <motion.ul className={"flex flex-col gap-[16px]"}>
            <motion.li
              variants={itemsFramer}
              className={"font-bold text-[28px] capitalize"}
            >
              <Link to={NAVIGATE_ROUTES.DRIVERS_LANDING}>{t("to-drivers")}</Link>
            </motion.li>
            <motion.li
              variants={itemsFramer}
              className={"font-bold text-[28px] capitalize"}
            >
              <Link to={NAVIGATE_ROUTES.PARTNER_LANDING}>{t("to-partners")}</Link>
            </motion.li>
          </motion.ul>
        </div>
        <div className={"flex flex-col mb-[5vh] mt-auto"}>
          <LanguageSwitcher className={"flex flex-col"} isMobile />
        </div>
      </div>
    </motion.nav>
  );
};

export const MotorHeader = () => {
  const { t } = useTranslation();
  const { widthD } = useDimension();
  const [isOpen, setOpen] = useState(false);
  const toggleOpen = () => setOpen((prevState) => !prevState);
  const onClose = () => setOpen(false);

  return (
    <nav className={"flex flex-col bg-custom-black"}>
      <div className="container-landing flex items-center justify-start py-[14px] md:py-[12px] w-full relative">
        <div
          className={"relative flex items-center mr-[12px] md:hidden top-[2px]"}
        >
          <button onClick={toggleOpen} aria-label={"open mobile menu"}>
            <BurgerMenu />
          </button>
        </div>
        <div className="flex items-center">
          <Link
            className="flex"
            to={NAVIGATE_ROUTES.MAIN}
            aria-label={"go to main page"}
          >
            <AppLandingLogo className={"w-auto h-[24px] md:h-[28px]"} />
          </Link>
        </div>
        <div className="ml-auto hidden md:flex items-center gap-[16px]">
          <LanguageSwitcher className="block md:inline-block transition-transform transform hover:-translate-y-1 z-[300]" />
          <NavLink url={NAVIGATE_ROUTES.DRIVERS_LANDING}>{t("to-drivers")}</NavLink>
          <NavLink url={NAVIGATE_ROUTES.PARTNER_LANDING} isHighlight>
            {t("to-partners")}
          </NavLink>
        </div>
        {widthD < 768 ? <MobileMenu isOpen={isOpen} onClose={onClose} /> : null}
      </div>
    </nav>
  );
};
