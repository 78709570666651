/**
 * Сервисы для transactions
 */

import { appConfig } from "@api/config";
import { IDType } from "@common/Types";

const src = appConfig.api.dashboard;
const srcBankAccounts = appConfig.api.bank_accounts;

export const transactionsPath = {
  transactions: `${src}/drivers/transactions/`,
  reports: `${src}/drivers/transactions/report/`,
  withdraw: `${src}/drivers/withdraws/`,
  adminTransactions: `${src}/admin/transaction-histories/`,
  bankAccounts: `${srcBankAccounts}`,
  bankAccountsBalance: (id: IDType | null) =>
    `${srcBankAccounts}/${id}/balance/`,
};
