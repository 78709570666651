import React from "react";

import { strokeColorClasses } from "@common/Constants";
import { SVGProps } from "@common/Types";

type Props = SVGProps;

export const PlusIcon = ({
  width = 20,
  height = 20,
  strokeColor = "brand-1000",
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={strokeColorClasses[strokeColor]}
    >
      <path
        d="M15 10H5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 5V15"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
