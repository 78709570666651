import React, { ComponentProps, ReactNode } from "react";

import { CommonFieldParams } from "@common/Types";
import { cn } from "@common/Utils";

import { Description } from "../description";
import { cardDescriptionSizeClasses } from "./variants";

type Props = ComponentProps<"div"> &
  Pick<CommonFieldParams<unknown>, "name" | "Icon"> & {
    size: "sm" | "md";
    value?: ReactNode;
  };

export const CardDescription = ({
  className,
  name,
  value,
  Icon,
  size,
  ...rest
}: Props) => (
  <div className={cn(cardDescriptionSizeClasses[size], className)} {...rest}>
    {Icon && <div className="bg-neutral-bg-75 p-3 rounded-lg">{Icon}</div>}

    <Description
      params={{
        name,
        value,
      }}
    />
  </div>
);
