import { TARGETS } from "../targets";

export const COLOR_CONFIGS = {
  [TARGETS.MOTOR]: {
    COLOR_BRAND_800: "#008295",
    COLOR_BRAND_600: "#2BB5C8",
  },
  [TARGETS.NAVI]: {
    COLOR_BRAND_800: "#173686",
    COLOR_BRAND_600: "#2855CB",
  },
};
