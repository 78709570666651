import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { usersService } from "@api/services";
import { queryClient } from "@common/Libs/react_query";
import { IDType, UserStatusType } from "@common/Types";

type Params = {
  id: IDType;
  data: UserStatusType;
};

export const useEditUserStatus = () => {
  return useMutation({
    mutationFn: ({ id, data }: Params) => usersService.editUserStatus(id, data),
    onSuccess: (_, { data = {} }) => {
      toast.success(
        `Пользователь ${data?.is_active ? "активирован" : "деактивирован"}`,
      );
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.DASHBOARD_USERS] });
    },
  });
};
