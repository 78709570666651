import { FilterTabType } from "@common/Types";

export const removePlusFromPlaceholder = (placeholder: string) => {
  if (placeholder.charAt(0) === "+") {
    return placeholder.substring(1);
  }
  return placeholder;
};

export function parseFilenameFromContentDisposition(
  contentDisposition: string | null,
  defaultFilename: string,
): string {
  if (contentDisposition) {
    const matches = contentDisposition.match(/filename="(.+?)"/);
    if (matches && matches.length === 2) {
      return matches[1];
    }
  }
  return defaultFilename;
}

export const enumToLabelValueArray = (
  enumObj: Record<string, string>,
): FilterTabType[] => {
  return Object.keys(enumObj).map((key) => ({
    label: enumObj[key],
    value: key,
  }));
};

export const findObjDifference = <T extends Record<string, any>>(
  obj1: T,
  obj2: T,
) => {
  const diffs = [];

  for (const key in obj2) {
    if (obj2[key] !== obj1[key]) {
      diffs.push({ key: key, value: obj2[key] });
    }
  }

  return diffs;
};

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));
