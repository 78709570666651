import React from "react";
import { Bar } from "react-chartjs-2";

import { StatusStatisticsAverageResponse } from "@common/Types";
import {
  formatDateToMonth,
  formatSecondsToHoursAndMinutes,
} from "@common/Utils";
import { Col, Loader, Row, Typography } from "@components/Elements";

type Props = {
  data?: StatusStatisticsAverageResponse;
  title: string;
  isLoading: boolean;
  dateDifferenceInDays: number;
};

export const BarStatistics = ({
  data,
  title,
  isLoading,
  dateDifferenceInDays,
}: Props) => {
  const getPeriodString = (
    data?: StatusStatisticsAverageResponse,
    key?: "previous_period" | "selected_period",
  ) => {
    if (data && key) {
      const { start_date, end_date } = data[key];

      const start = new Date(start_date);
      const end = new Date(end_date);

      const startFormatted = formatDateToMonth(start);
      const endFormatted = formatDateToMonth(end);

      return `C ${startFormatted} по ${endFormatted}`;
    }
  };

  const getAverage = (
    data?: StatusStatisticsAverageResponse,
    key?: "previous_period" | "selected_period",
  ) => {
    return data && key ? data[key].avg : null;
  };

  const selectedPeriod = getPeriodString(data, "selected_period");
  const previousPeriod = getPeriodString(data, "previous_period");

  const selectedAverage = getAverage(data, "selected_period");
  const previousAverage = getAverage(data, "previous_period");

  const getAverageValue = () => {
    if (!previousAverage || !selectedAverage) return 0;

    if (dateDifferenceInDays <= 1) {
      return Math.abs(selectedAverage - previousAverage);
    }

    const values = previousAverage + selectedAverage;
    return values / dateDifferenceInDays;
  };

  const getAverageValuePercentage = () => {
    if (previousAverage && selectedAverage) {
      let percentage = 0;

      if (previousAverage <= selectedAverage) {
        percentage = previousAverage / selectedAverage;
      } else {
        percentage = selectedAverage / previousAverage;
      }

      return (100 - percentage * 100).toFixed(2);
    }
  };

  const getIsAverageMoreThanPrevPeriod = () => {
    if (previousAverage && selectedAverage) {
      return previousAverage <= selectedAverage;
    }
  };

  const averageTime = getAverageValue();
  const averageTimePercentage = getAverageValuePercentage();
  const isAverageMoreThanPrevPeriod = getIsAverageMoreThanPrevPeriod();

  return (
    <Loader loading={isLoading} height={268}>
      <Col
        className="p-4 bg-neutral-bg-0 shadow-new rounded-[8px] w-full h-full min-w-[360px]  xl:max-h-[268px]"
        justifyContent="justify-between"
      >
        <Row
          alignItems="items-end"
          justifyContent="justify-between"
          className="border-b-neutral-border-100 border-b pb-4 mb-4 w-full"
        >
          <Typography
            as="h3"
            variant="body-1-b"
            color="neutral-800"
            className="font-bold"
          >
            {title}
          </Typography>
          {averageTime && averageTimePercentage && (
            <Row alignItems="items-end" className="gap-2">
              <Typography
                as="h3"
                variant="body-1-b"
                color="neutral-800"
                className="font-bold"
              >
                {formatSecondsToHoursAndMinutes(averageTime)}
              </Typography>
              <Typography
                as="span"
                variant="caption-a"
                color={
                  isAverageMoreThanPrevPeriod ? "positive-600" : "neutral-800"
                }
                className="flex flex-row items-end mb-[2px]"
              >
                {averageTimePercentage}%
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d={
                      isAverageMoreThanPrevPeriod
                        ? "M6.00004 4.66667L11.3334 4.66667L11.3334 10"
                        : "M5.99992 11.3333L11.3333 11.3333L11.3333 6"
                    }
                    stroke={isAverageMoreThanPrevPeriod ? "#31AF68" : "#616163"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d={
                      isAverageMoreThanPrevPeriod
                        ? "M11.3334 4.66536L4.66671 11.332"
                        : "M11.3333 11.3346L4.66659 4.66797"
                    }
                    stroke={isAverageMoreThanPrevPeriod ? "#31AF68" : "#616163"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Typography>
            </Row>
          )}
        </Row>
        <Row
          className="w-full min-w-[330px]"
          justifyContent="justify-between"
          alignItems="items-center"
        >
          <Row className="w-full h-[180px]">
            <Bar
              data={{
                labels: [selectedPeriod, previousPeriod],
                datasets: [
                  {
                    data: [selectedAverage, previousAverage].map(
                      (i) => i && i / 3600,
                    ),
                    backgroundColor: "#44CEE2",
                    hoverBackgroundColor: "#119BAF",
                  },
                ],
              }}
              options={{
                responsive: true,
                scales: {
                  y: {
                    beginAtZero: true,
                    min: 0,
                    max: 24,
                    ticks: {
                      stepSize: 6,
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    displayColors: false,
                    callbacks: {
                      label: (context) => {
                        return (
                          formatSecondsToHoursAndMinutes(
                            (context?.raw as number) * 3600,
                          ) || ""
                        );
                      },
                    },
                    bodyFont: {
                      size: 14,
                      family: "inter, sans-serif",
                      weight: "400",
                    },
                    titleFont: {
                      size: 14,
                      family: "inter, sans-serif",
                      weight: "400",
                    },
                  },
                },
                maintainAspectRatio: false,
              }}
            />
          </Row>
        </Row>
      </Col>
    </Loader>
  );
};
