import { MultiSelect as MantineMultiSelect, Tooltip } from "@mantine/core";
import { Field, useField } from "formik";
import { isEmpty } from "radash";
import React from "react";

import { TooltipIcon } from "@assets/Icons";
import { Row, Typography } from "@components/Elements";

import { MultiSelectProps } from "./types";
import { MultiSelectClasses } from "./variants";

export const MultiSelect = ({
  view = "default",
  label,
  tooltip,
  required,
  error,
  children,
  ...rest
}: MultiSelectProps) => {
  const {
    opened,
    label: tooltipLabel,
    children: tooltipChildren = <TooltipIcon />,
    ...tooltipProps
  } = tooltip ?? {};

  const isTooltipOpened = opened ?? !isEmpty(tooltip);

  return (
    <div className="w-full">
      <Row alignItems="items-center" className="inline-flex mb-1">
        <Typography className={MultiSelectClasses[view].label} as="label">
          {label}

          {required && <span className="text-error ml-1">*</span>}
        </Typography>

        {isTooltipOpened && (
          <Tooltip label={tooltipLabel} className="ml-1" {...tooltipProps}>
            {tooltipChildren}
          </Tooltip>
        )}
      </Row>

      <MantineMultiSelect
        error={error}
        classNames={MultiSelectClasses[error ? "error" : view]}
        {...rest}
      >
        {children}
      </MantineMultiSelect>
    </div>
  );
};

export const FormMultiSelect = ({
  field,
  ...rest
}: MultiSelectProps & { field: string }) => {
  const [fieldProps, { touched, error }, { setValue, setTouched }] =
    useField(field);

  const currentValue = String(fieldProps.value);

  return (
    <Field
      as={MultiSelect}
      error={touched && error}
      {...fieldProps}
      {...rest}
      value={currentValue}
      onChange={setValue}
      onBlur={setTouched}
    />
  );
};
