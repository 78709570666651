import React from "react";

import { FilterTabType } from "@common/Types";

import { CheckboxList } from "../checkbox-list";
import { DriverStatusIcon } from "./assets/icons";

type Props<T> = {
  filters: T;
  setFilters: (filters: T) => void;
  options: FilterTabType[];
  field?: keyof T;
};

export const StatusFilter = <T,>({
  filters,
  setFilters,
  options,
  field = "status" as keyof T,
}: Props<T>) => {
  return (
    <CheckboxList
      singleChoice
      MainIcon={<DriverStatusIcon />}
      title="Статус"
      filters={filters}
      setFilters={setFilters}
      field={field}
      options={options}
    />
  );
};
