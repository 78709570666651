import { PaymentProviderOptions } from "@common/Types";
import { APP_FILTERS } from "@configs";

export const PAYMENT_PROVIDER_LABEL = {
  cash: "Наличными",
  corporate: "Корпоративный аккаунт",
  family: "Семейный аккаунт",
  ...APP_FILTERS.PAYMENT_PROVIDER_LABEL,
} as Record<PaymentProviderOptions, string>;

export const BANK_PROVIDER_FILTER_TYPE = APP_FILTERS.BANK_PROVIDER_FILTER_TYPE;
