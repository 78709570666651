import { twMerge } from "tailwind-merge";
import React from "react";

import { strokeColorClasses } from "@common/Constants";
import { SVGProps } from "@common/Types";

type CloseIconProps = {
  borderColor?: string;
  borderWidth?: number;
  borderRadius?: string;
};

type SVGCloseIconProps = SVGProps & CloseIconProps;

export const CloseIcon: React.FC<SVGCloseIconProps> = ({
  borderColor,
  borderWidth = 1.5,
  borderRadius = "50%",
  className,
  strokeColor = "neutral-900",
  size = "24",
  onClick,
  ...rest
}) => {
  const hasBorder = borderColor
    ? `${borderWidth}px solid ${borderColor}`
    : "none";

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        display: "inline-block",
        border: hasBorder,
        borderRadius: borderRadius,
      }}
      className={twMerge(className, strokeColorClasses[strokeColor])}
      onClick={onClick}
      {...rest}
    >
      <path
        d="M6 6L18 18"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 6L6 18"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
