import { Checkbox } from "@mantine/core";
import { useFormikContext } from "formik";
import React, { ChangeEvent } from "react";

import { useDelay, useDimension } from "@common/Hooks";

import { FilterValueType } from "../common/types";
import { updateChips } from "../common/utils";

type Props<T> = {
  field: keyof T;
  label?: string;
};

export const CheckboxFilter = <T,>({ label = "Выберите", field }: Props<T>) => {
  const { isDesktop } = useDimension();

  const { values, setValues, submitForm } =
    useFormikContext<FilterValueType<T>>();

  const debounceSubmitForm = useDelay(submitForm, 300);

  const checkBoxOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    const chips = values.chips || [];

    const updatedChips = updateChips(chips, field as string, label);

    setValues({
      ...values,
      chips: updatedChips,
      [field]: target.checked,
    });

    if (isDesktop) {
      debounceSubmitForm();
    }
  };

  return (
    <Checkbox
      size="md"
      label={label}
      checked={values[field] as unknown as boolean}
      onChange={checkBoxOnChange}
    />
  );
};
