import React from "react";

import { strokeColorClasses } from "@common/Constants";
import { SVGProps } from "@common/Types";

type Props = SVGProps;

export const ChangePasswordIcon = ({ strokeColor = "brand-0" }: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={strokeColorClasses[strokeColor]}
    >
      <path
        d="M7.84898 11.7354C7.99498 11.8814 7.99498 12.1194 7.84898 12.2654C7.70298 12.4114 7.46498 12.4114 7.31898 12.2654C7.17298 12.1194 7.17298 11.8814 7.31898 11.7354C7.46498 11.5884 7.70198 11.5884 7.84898 11.7354"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.265 11.7354C12.411 11.8814 12.411 12.1194 12.265 12.2654C12.119 12.4114 11.881 12.4114 11.735 12.2654C11.589 12.1194 11.589 11.8814 11.735 11.7354C11.881 11.5884 12.119 11.5884 12.265 11.7354"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 3H14C15.105 3 16 3.895 16 5C16 3.895 16.895 3 18 3H19"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 19V5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 21H18C16.895 21 16 20.105 16 19C16 20.105 15.105 21 14 21H13"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 17H5C3.895 17 3 16.105 3 15V9C3 7.895 3.895 7 5 7H13"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 17H19C20.105 17 21 16.105 21 15V9C21 7.895 20.105 7 19 7H16"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
