import React from "react";

import { strokeColorClasses } from "@common/Constants";
import { SVGProps } from "@common/Types";

type Props = SVGProps;

export const MessageIcon = ({ strokeColor = "brand-1000" }: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={strokeColorClasses[strokeColor]}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.43585 16.5652L16.46 10.9827C17.2917 10.5969 17.2917 9.40187 16.46 9.01604L4.43585 3.43521C3.57335 3.03521 2.66085 3.88354 2.98335 4.78604L4.84419 9.99771L2.98335 15.2152C2.66085 16.1169 3.57335 16.9652 4.43585 16.5652Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.8418 10.0002H17.0835"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
