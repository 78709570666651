export enum PARK_STATUSES {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export enum TRANSACTION_TYPES {
  WITHDRAW = "withdraw",
  REPLENISHMENT = "replenishment",
}

export enum ACTIVATION_TYPES {
  ACTIVATION = "activation",
  DEACTIVATION = "deactivation",
}

export enum APPLICATION_STATUSES {
  NEW = "new",
  REJECTED = "rejected",
  APPROVED = "approved",
}

export enum FRAUD_STATUSES {
  CREATED = "created",
  IN_MODERATION = "in_moderation",
  BLOCKED = "blocked",
  UNLOCKED = "unlocked",
  APPROVE = "approve",
  DECLINE = "decline",
}

export const ROLES = {
  SUPERADMIN: "superadmin",
  CITYSUPERADMIN: "citysuperadmin",
  DISPATCHER: "dispatcher",
  PARKADMIN: "parkadmin",
  DRIVER: "driver",
  RIDER: "rider",
};

export const APP_BUILD_TARGET = process.env.REACT_APP_BUILD_TARGET;

export const TOKEN = {
  ACCESS_TOKEN: `${APP_BUILD_TARGET}_access_token`,
  REFRESH_TOKEN: `${APP_BUILD_TARGET}_refresh_token`,
} as const;

export enum WS_TYPE {
  DRIVERS = "driver-support-message",
  RIDERS = "rider-support-message",
}

export enum APP_TYPE {
  IOS = "ios-rider",
  ANDROID_DRIVER = "android-driver",
  ANDROID_RIDER = "android-rider",
}

export const formatOfDate = "dd-MM-yyyy";

export const separatedFormatOfDate = "yyyy-MM-dd";

export const ReportDriverBalanceType = {
  replenishment_by_driver: "Реестр по электронному кошельку",
  replenishment_by_ride: "Реестр по поездкам",
  withdraw_commission: "Реестр  по комиссии",
  withdraw_driver: "Реестр по выводу",
  promotions: "Реестр по акциям",
  rides: "Отчет по поездкам",
  drivers: "Отчет по водителям",
  riders: "Отчет по пассажирам",
  driver_ride_promotions: "Отчет по целям водителей",
  admin_transaction: "Отчет по административным транзакциям",
  driver_rides: "Отчет по часам и поездкам",
} as const;
