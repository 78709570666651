import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { parksService } from "@api/services";
import { queryClient } from "@common/Libs/react_query";
import { ParksDetailType } from "@common/Types";

export const useEditPark = () => {
  return useMutation({
    mutationFn: (park: Partial<ParksDetailType>) => parksService.editPark(park),

    onSuccess: () => {
      toast.success("Таксопарк успешно изменен");

      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PARKS_DETAIL] });
    },
    retry: 0,
  });
};
