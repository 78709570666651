import { httpClient } from "@common/Libs/axios";
import {
  CreateOperationFormType,
  EditHistoryType,
  FamilyAccountType,
  QueryParams,
  RiderDetailPageType,
  RiderRidesParams,
  RiderRidesType,
  RidersEditHistoryParams,
  RiderTypeResponse,
  UpdateRiderStatusParams,
  UpdateRiderStatusType,
} from "@common/Types";
import {
  buildDateRangeFromTextRange,
  commaSerializer,
  removeEmptyFilterValues,
} from "@common/Utils";

import { ridersPath } from "./path";

/**
 * Описание методов riders.
 */

export const ridersService = {
  getRiders: (params: QueryParams) => {
    const { filters, ...rest } = params;

    return httpClient.get<RiderTypeResponse>(ridersPath.riders, {
      params: { ...removeEmptyFilterValues(filters), ...rest },
      paramsSerializer: commaSerializer,
    });
  },
  cleanRidersDebt: (rider_id: string) =>
    httpClient.post(ridersPath.clean_riders_debt(rider_id), {}),
  getRiderDetail: (rider_id: string) =>
    httpClient.get<RiderDetailPageType>(ridersPath.rider_detail(rider_id)),
  getRiderEditHistory: ({
    rider_id,
    filters,
    skip,
    limit,
  }: RidersEditHistoryParams) => {
    const dateRange = buildDateRangeFromTextRange("select_range")(filters);
    const buildedFilters = removeEmptyFilterValues({
      ...filters,
      ...dateRange,
    });
    return httpClient.get<EditHistoryType>(
      ridersPath.rider_edit_history(rider_id),
      {
        params: { ...buildedFilters, skip, limit },
        paramsSerializer: commaSerializer,
      },
    );
  },
  createRiderBonuses: (rider_id: string, values: CreateOperationFormType) =>
    httpClient.post<CreateOperationFormType, CreateOperationFormType>(
      ridersPath.rider_bonuses(rider_id),
      values,
    ),
  getFamilyAccountDetail: (rider_id: string) =>
    httpClient.get<FamilyAccountType>(ridersPath.family_account(rider_id)),
  updateRiderStatus: ({
    rider_id,
    block,
    block_reason,
  }: UpdateRiderStatusParams) =>
    httpClient.post<UpdateRiderStatusType, UpdateRiderStatusType>(
      ridersPath.rider_detail(rider_id),
      {
        block,
        block_reason,
      },
    ),
  getRiderRides: ({ limit, skip, filters, rider_id }: RiderRidesParams) => {
    const buildFilters = removeEmptyFilterValues(filters);

    return httpClient.get<RiderRidesType>(ridersPath.rider_rides(rider_id), {
      params: { limit, skip, ...buildFilters },
      paramsSerializer: commaSerializer,
    });
  },
};
