import { Field, useField } from "formik";
import React from "react";
import { NumberFormatValues } from "react-number-format";

import { APP_COMMON } from "@configs";

import { InputPhoneMask } from "../input-phone-mask";
import { TextInput } from "../text-input";
import { PhoneInputProps } from "./types";

const format = APP_COMMON.PHONE.mask;
const defaultPlaceholder = APP_COMMON.PHONE.placeholder;

export const PhoneInput = ({
  placeholder = defaultPlaceholder,
  ...props
}: PhoneInputProps) => {
  return (
    <InputPhoneMask
      mask="_"
      format={format}
      autoComplete="off"
      placeholder={placeholder}
      valueIsNumericString={true}
      customInput={TextInput as any} // FIX_ME
      {...props}
    />
  );
};

export const FormPhoneInput = ({
  field,
  ...rest
}: PhoneInputProps & { field: string }) => {
  const [fieldProps, { touched, error }, { setValue }] = useField(field);

  return (
    <Field
      as={PhoneInput}
      error={touched && error}
      onValueChange={({ value }: NumberFormatValues) => setValue(value)}
      {...fieldProps}
      {...rest}
      onChange={undefined}
    />
  );
};
