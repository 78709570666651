import {
  Checkbox as MantineCheckbox,
  CheckboxGroup as MantineCheckboxGroup,
} from "@mantine/core";
import { Field, useField } from "formik";
import React from "react";

import { Col } from "../flexbox";
import { Typography } from "../typography";
import { CheckboxGroupProps } from "./types";
import { CheckboxGroupClasses } from "./variants";

// Imported Checkbox from Mantine because CheckboxGroup only supports Mantine components.

export const CheckboxGroup = ({
  view = "brand",
  size = "xs",
  data,
  label,
  ...rest
}: CheckboxGroupProps) => {
  return (
    <Col className="gap-[7px]">
      <Typography
        color="neutral-800"
        variant="caption-b"
        className="font-medium"
      >
        {label}
      </Typography>

      <MantineCheckboxGroup
        classNames={CheckboxGroupClasses[view]}
        size={size}
        {...rest}
      >
        {data.map(({ label, value }, index) => (
          <MantineCheckbox
            key={`${value}-${index}`}
            label={label}
            value={value}
          />
        ))}
      </MantineCheckboxGroup>
    </Col>
  );
};

export const FormCheckboxGroup = ({
  field,
  ...rest
}: CheckboxGroupProps & { field: string }) => {
  const [fieldProps, { touched, error }, { setValue }] = useField(field);

  return (
    <Field
      as={CheckboxGroup}
      error={touched && error}
      {...fieldProps}
      {...rest}
      onChange={setValue}
    />
  );
};
