import { COLOR_CONFIGS } from "./colors";
import { COMMON_CONFIGS } from "./common";
import { COMPONENT_CONFIGS } from "./components";
import { DICTIONARY_CONFIGS } from "./dictionary";
import { FILTER_CONFIGS } from "./filters";
import { PERMISSION_CONFIGS } from "./permissions";
import { TargetType } from "./targets";

const BUILD_TARGET = process.env.REACT_APP_BUILD_TARGET as TargetType;

const common = COMMON_CONFIGS[BUILD_TARGET];
const filters = FILTER_CONFIGS[BUILD_TARGET];
const colors = COLOR_CONFIGS[BUILD_TARGET];
const dictionary = DICTIONARY_CONFIGS[BUILD_TARGET];
const components = COMPONENT_CONFIGS[BUILD_TARGET];
const permissions = PERMISSION_CONFIGS[BUILD_TARGET];

const APP_CONFIGS = {
  APP_COMMON: common,
  APP_FILTERS: filters,
  APP_COLORS: colors,
  APP_DICTIONARY: dictionary,
  APP_COMPONENT: components,
  APP_PERMISSIONS: permissions,
};

export { TARGETS } from "./targets";
export const {
  APP_COMPONENT,
  APP_COLORS,
  APP_DICTIONARY,
  APP_FILTERS,
  APP_COMMON,
  APP_PERMISSIONS,
} = APP_CONFIGS;
