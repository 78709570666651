import React from "react";

type Props = {
  onClick: () => void;
  headline: string;
};

const GoBackButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button onClick={onClick}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_11036_4092)">
          <path
            d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
            fill="#26282C"
          />
        </g>
        <defs>
          <clipPath id="clip0_11036_4092">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </button>
  );
};

export const FAQHeadline = ({ onClick, headline }: Props) => {
  return (
    <div className={"relative w-full flex items-center h-[48px] px-[48px]"}>
      <div className={"absolute left-[12px] flex flex-col"}>
        <GoBackButton onClick={onClick} />
      </div>
      <div className={"w-full flex flex-col text-center"}>
        <span className={"text-[20px] font-medium leading-[1.15]"}>
          {headline}
        </span>
      </div>
    </div>
  );
};
