/**
 * Сервисы для photo control
 */

import { appConfig } from "@api/config";
import { IDType } from "@common/Types";

const src = appConfig.api.dashboard;

export const photoControlPath = {
  photo_control_list: `${src}/drivers/photo-control`,
  photo_control_by_id: (id?: IDType | null) =>
    `${src}/drivers/photo-control/${id}`,
};
