import React from "react";

import { cn } from "@common/Utils";
import { Row } from "@components/Elements";

type Props = {
  className?: string;
  children: React.ReactNode;
};

export const RightGroup = ({ children, className }: Props) => {
  return (
    <Row className={cn("gap-1 w-auto", className)}>{children}</Row>
  );
};
