import { CheckboxView, CheckboxViewStyles } from "./types";

const font = {
  color: "!text-neutral-text-100",
  size: "!text-body-1-a",
};

const common = {
  focus: "focus:ring-brand-border-500",
  checked: "checked:bg-brand-bg-500 checked:border-none",
};

export const CheckboxClasses: Record<CheckboxView, CheckboxViewStyles> = {
  brand: {
    label: `${font.color} ${font.size}`,
    input: `border-neutral-border-300 ${common.focus} ${common.checked}`,
    icon: "opacity-0",
  },
};
