import { useFormikContext } from "formik";
import React from "react";

import { useDelay, useDimension } from "@common/Hooks";

import { PaymentIcon } from "../assets/icons";
import { FilterValueType } from "../common/types";
import { updateChips } from "../common/utils";
import { CustomSelect } from "./custom-select";

type Props<T> = {
  label?: string;
  field: keyof T;
  options: { value: string; label: string }[];
  clearable?: boolean;
};

export const PaymentTypeFilter = <T,>({
  label = "Вид платежа",
  field,
  options,
  clearable = true,
  ...props
}: Props<T>) => {
  const { isDesktop } = useDimension();
  const { values, setValues, submitForm } =
    useFormikContext<FilterValueType<T>>();

  const debounceSubmitForm = useDelay(submitForm, 300);

  const handleSelect = (selectedValue: string) => {
    const fullValue = options.find((option) => option.value === selectedValue);

    const value = selectedValue === "all" ? null : selectedValue;
    const chips = values.chips || [];

    const updatedChips = updateChips(
      chips,
      field as string,
      fullValue?.label as string,
    );

    setValues({ ...values, [field]: value, chips: updatedChips });

    if (isDesktop) {
      debounceSubmitForm();
    }
  };

  return (
    <CustomSelect
      data={options}
      value={values[field] as unknown as string}
      onChange={handleSelect}
      clearable={clearable}
      label={isDesktop ? "" : label}
      icon={<PaymentIcon />}
      placeholder="Все"
      {...props}
    />
  );
};
