import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { parksService } from "@api/services";
import { queryClient } from "@common/Libs/react_query";
import { ParksDetailType } from "@common/Types";

export const useCreatePark = () => {
  return useMutation({
    mutationFn: (park: Partial<ParksDetailType>) =>
      parksService.createPark(park),

    onSuccess: (_, variables) => {
      toast.success(`Таксопарк '${variables.name}' успешно создан`);

      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PARKS] });
    },
    retry: 0,
  });
};
