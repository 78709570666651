import React from "react";

export const BatteryIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.75 7V5C17.75 4.448 18.198 4 18.75 4H20.75C21.302 4 21.75 4.448 21.75 5V7"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.75 11.5V14.5"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.25 13H16.25"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.25 13H6.25"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75 7V5C3.75 4.448 4.198 4 4.75 4H6.75C7.302 4 7.75 4.448 7.75 5V7"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.75 7H21.75C22.302 7 22.75 7.448 22.75 8V18C22.75 19.105 21.855 20 20.75 20H4.75C3.645 20 2.75 19.105 2.75 18V8C2.75 7.448 3.198 7 3.75 7H7.75L9.457 5.293C9.645 5.105 9.899 5 10.164 5H15.336C15.601 5 15.856 5.105 16.043 5.293L17.75 7V7Z"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
