import { Progress, ProgressProps } from "@mantine/core";
import React from "react";

import { APP_COLORS } from "@configs";

/**
 * ProgressBar component to display a visual representation of progress.
 *
 * @param {ProgressProps} params - Props for the Progress component from Mantine.
 * @returns {JSX.Element} A Progress component rendered with the provided props.
 */

export const ProgressBar = (params: ProgressProps) => {
  const color = params.color || APP_COLORS.COLOR_BRAND_800;
  return <Progress {...params} color={color} />;
};
