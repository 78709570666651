import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { reportsService } from "@api/services";
import { REFETCH_INTERVAL } from "@common/Constants";
import { ReportTypeFilters } from "@common/Types";

type Params = ReportTypeFilters;

export const useGetReports = (filters: Params) => {
  return useQuery({
    queryKey: [QUERY_KEYS.REPORTS, filters],
    queryFn: async () => await reportsService.getReports(filters),
    refetchInterval: REFETCH_INTERVAL,
  });
};
