/**
 * Описание методов promotion.
 */

import { httpClient } from "@common/Libs/axios";
import {
  IDType,
  PhotoControlDetailType,
  PhotoControlHandleStatusType,
  PhotoControlListType,
  PhotoControlLitFilterType,
} from "@common/Types";
import { buildCommonFiltersValues } from "@common/Utils";

import { photoControlPath } from "./paths";

export const photoControlService = {
  getPhotoControlList: ({
    skip,
    limit,
    filters,
  }: PhotoControlLitFilterType) => {
    return httpClient.get<PhotoControlListType>(
      photoControlPath.photo_control_list,
      {
        params: { skip, limit, ...buildCommonFiltersValues(filters) },
      },
    );
  },

  getPhotoControlDetail: (id: IDType) => {
    return httpClient.get<PhotoControlDetailType>(
      photoControlPath.photo_control_by_id(id),
    );
  },

  setPhotoControlStatus: ({
    id,
    ...otherParams
  }: PhotoControlHandleStatusType) => {
    return httpClient.put<
      PhotoControlHandleStatusType,
      Omit<PhotoControlHandleStatusType, "id">
    >(photoControlPath.photo_control_by_id(id), otherParams);
  },
};
