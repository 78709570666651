import * as Sentry from "@sentry/react";
import toast from "react-hot-toast";

import { removeRefreshToken, removeToken } from "@common/Utils";
import { removeUserRole } from "@common/Utils/localstorage/user-localstorage";

/**
 * logout
 */

export const useLogout = () => {
  try {
    Sentry.setUser(null);
    removeToken();
    removeRefreshToken();
    removeUserRole();
    window.location.reload();
  } catch (error) {
    toast.error("Не удалось выйти");
  }
};
