import qs from "query-string";
import { chain, isEmpty } from "radash";

export const commaSerializer = <T extends Record<string, any>>(filters: T) => {
  return qs.stringify(filters, { arrayFormat: "comma" });
};

export const defaultSerializer = <T extends Record<string, any>>(
  filters: T,
) => {
  return qs.stringify(filters);
};

export const noneSerializer = <T extends Record<string, any>>(filters: T) => {
  return qs.stringify(filters, { arrayFormat: "none" });
};

//  API helpers
export const removeEmptyFilterValues = <T extends Record<string, unknown>>(
  filters: T | null | undefined,
): Partial<T> | null | undefined => {
  if (!filters) return filters;

  return Object.fromEntries(
    Object.entries(filters)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          const filteredArray = value.filter((item) => !isEmpty(item));
          return filteredArray.length > 0 ? [key, filteredArray] : null;
        }
        return !isEmpty(value) || typeof value === "boolean"
          ? [key, value]
          : null;
      })
      .filter((entry): entry is [string, unknown] => entry !== null), // Remove null entries
  ) as Partial<T>;
};

export const removeFilterByKeyValue = <T extends object>(
  keyToBeRemoved: keyof T,
  value: T[keyof T],
  filters: T,
): T => {
  return Object.keys(filters).reduce((acc, currentKey) => {
    const typedKey = currentKey as keyof T;
    if (typedKey === keyToBeRemoved && filters[typedKey] === value) {
      return acc;
    }
    acc[typedKey] = filters[typedKey];
    return acc;
  }, {} as T);
};

export const removeEmptyFields = <T extends object>(values: T): T => {
  return Object.keys(values).reduce((acc, currentKey) => {
    const key = currentKey as keyof T;

    const isEmptyValues =
      typeof values[key] === "boolean" ? true : !isEmpty(values[key]); // not empty

    if (isEmptyValues) {
      acc[key] = values[key];
    }
    return acc;
  }, {} as T);
};

export const buildCommonFiltersValues = <T>(filters: T): Partial<T> => {
  const chained = chain(removeEmptyFilterValues);
  return chained(filters);
};
