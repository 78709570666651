import { TransactionFilterType } from "@common/Types";

// PAYMENT
export const PAYMENT_FILTERS_MOTOR = [
  { label: "Картой", value: "epay" },
  { label: "Каспи", value: "kaspi" },
];

export const BANK_PROVIDER_FILTER_TYPE_MOTOR = [
  { label: "Visa/MasterCard", value: "freedom" },
  { label: "Halyk", value: "epay" },
  { label: "Altyn-i", value: "altyn" },
];

export const PAYMENT_PROVIDER_MOTOR_LABEL = {
  epay: "Картой",
  kaspi: "Каспи",
};


export const TRANSACTION_TYPES_MOTOR = [
  {
    value: TransactionFilterType.KASPI,
    label: "Пополнение через Kaspi",
    group: "Пополнение",
  },
  {
    value: TransactionFilterType.HALYK_WITHDRAW,
    label: "Вывод средств - Халык",
    group: "Списания",
  },
  {
    value: TransactionFilterType.HALYK_WITHDRAW_COMMISSION,
    label: "Комиссия за вывод на Халык",
    group: "Списания",
  },
  {
    value: TransactionFilterType.ALTYN_WITHDRAW,
    label: "Вывод средств altyn - i",
    group: "Списания",
  },
  {
    value: TransactionFilterType.ALTYN_WITHDRAW_COMMISSION,
    label: "Комиссия на вывод altyn-i",
    group: "Списания",
  },
  {
    value: TransactionFilterType.COMMISSION_REFUND,
    label: "Возврат комиссии при неудачном выводе",
    group: "Пополнение",
  },
  {
    value: TransactionFilterType.FUNDS_UNLOCK,
    label: "Разморозка средств при неудачном выводе",
    group: "Пополнение",
  },
  {
    value: TransactionFilterType.FUNDS_WITHDRAW,
    label: "Вывод средств",
    group: "Списания",
  },
  {
    value: TransactionFilterType.WITHDRAW_COMMISSION,
    label: "Комиссия за вывод",
    group: "Списания",
  },
  {
    value: TransactionFilterType.CARD_WITHDRAW,
    label: "Вывод средств на карту Visa/MasterCard",
    group: "Списания",
  },
  {
    value: TransactionFilterType.CARD_WITHDRAW_COMMISSION,
    label: "Комиссия за вывод средств на карту Visa/MasterCard",
    group: "Списания",
  },
];