import { TARGETS } from "../targets";
import {
  BANK_PROVIDER_FILTER_TYPE_MOTOR,
  PAYMENT_FILTERS_MOTOR,
  PAYMENT_PROVIDER_MOTOR_LABEL,
  TRANSACTION_TYPES_MOTOR,
} from "./motor";
import {
  BANK_PROVIDER_FILTER_TYPE_NAVI,
  PAYMENT_FILTERS_NAVI,
  PAYMENT_PROVIDER_NAVI_LABEL,
  TRANSACTION_TYPES_NAVI,
} from "./navi";

export const FILTER_CONFIGS = {
  [TARGETS.MOTOR]: {
    TRANSACTION_TYPES: TRANSACTION_TYPES_MOTOR,
    PAYMENT_FILTERS: PAYMENT_FILTERS_MOTOR,
    BANK_PROVIDER_FILTER_TYPE: BANK_PROVIDER_FILTER_TYPE_MOTOR,
    PAYMENT_PROVIDER_LABEL: PAYMENT_PROVIDER_MOTOR_LABEL,
  },
  [TARGETS.NAVI]: {
    TRANSACTION_TYPES: TRANSACTION_TYPES_NAVI,
    PAYMENT_FILTERS: PAYMENT_FILTERS_NAVI,
    BANK_PROVIDER_FILTER_TYPE: BANK_PROVIDER_FILTER_TYPE_NAVI,
    PAYMENT_PROVIDER_LABEL: PAYMENT_PROVIDER_NAVI_LABEL,
  },
};
