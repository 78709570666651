import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { applicationService } from "@api/services";
import {
  DriverApplicationRequest,
  DriverApplicationResponse,
  QueryOptionsType,
} from "@common/Types";

export const useGetDriverApplications = (
  params: DriverApplicationRequest,
  options?: QueryOptionsType<DriverApplicationResponse>,
) => {
  return useQuery<DriverApplicationResponse>({
    queryKey: [QUERY_KEYS.DRIVER_APPLICATIONS, params],
    queryFn: async () => await applicationService.getDriverApplications(params),
    ...options,
  });
};
