import { useMutation } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { promotionsService } from "@api/services";
import { queryClient } from "@common/Libs/react_query";
import { IDType } from "@common/Types";

export const useDeletePromotions = () => {
  return useMutation({
    mutationFn: (promotionId: IDType) =>
      promotionsService.deletePromotion(promotionId),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PROMOTIONS] });
    },
  });
};
