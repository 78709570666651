import { Tooltip } from "@mantine/core";
import { TimeInput as MantineTimeInput } from "@mantine/dates";
import { Field, useField } from "formik";
import { isEmpty } from "radash";
import React, { useEffect } from "react";

import { TooltipIcon } from "@assets/Icons";

import { Row } from "../flexbox";
import { Typography } from "../typography";
import { TimeInputProps } from "./types";
import { VIEW_CLASSES } from "./variants";

/**
 * TimeInput component renders a time input field with customizable labels, description,
 * icon, and error handling. It utilizes the Mantine `TimeInput` component as its base.
 *
 * @param {Props} props - The properties for the TimeInput component.
 * @returns {JSX.Element} The rendered TimeInput component.
 */

export const TimeInput = ({
  view = "default",
  label,
  required,
  error,
  excludeTime,
  tooltip,
  ...props
}: TimeInputProps) => {
  const divRef = React.useRef<HTMLDivElement>(null);

  const {
    opened,
    label: tooltipLabel,
    children: tooltipChildren = <TooltipIcon />,
    ...tooltipProps
  } = tooltip ?? {};

  const isTooltipOpened = opened ?? !isEmpty(tooltip);

  useEffect(() => {
    // hide select time in TimeInput
    if (divRef?.current && excludeTime) {
      const hourInput = divRef.current.querySelector(
        `input[aria-label="${excludeTime}"]`,
      );

      const nextSibling = hourInput?.nextElementSibling;

      const hasInput = hourInput instanceof HTMLInputElement;
      const hasNextSibling = nextSibling instanceof HTMLDivElement;

      if (hasInput) {
        hourInput.style.display = "none";
      }

      if (hasNextSibling) {
        nextSibling.style.display = "none";
      }
    }
  }, [excludeTime]);

  return (
    <div ref={divRef} className="w-full">
      <Row alignItems="items-center" className="inline-flex mb-1">
        <Typography className={VIEW_CLASSES[view].label} as="label">
          {label}

          {required && <span className="text-error ml-1">*</span>}
        </Typography>

        {isTooltipOpened && (
          <Tooltip label={tooltipLabel} className="ml-1" {...tooltipProps}>
            {tooltipChildren}
          </Tooltip>
        )}
      </Row>

      <MantineTimeInput
        hoursLabel="hours"
        minutesLabel="minutes"
        error={error}
        classNames={VIEW_CLASSES[error ? "error" : view]}
        {...props}
      />
    </div>
  );
};

export const FormTimeInput = ({
  field,
  ...rest
}: TimeInputProps & { field: string }) => {
  const [fieldProps, { touched, error }] = useField(field);

  return (
    <Field as={TimeInput} error={touched && error} {...fieldProps} {...rest} />
  );
};
