import { useFormikContext } from "formik";
import React from "react";

import { formatOfDate, RangeDateVariants } from "@common/Constants";
import { useDelay, useDimension } from "@common/Hooks";
import {
  DateRageFilterType,
  PredefinedDateRangeType,
  PredefinedRanges,
} from "@common/Types";
import { formatDateRange } from "@common/Utils";
import { DateRangeSelect } from "@components/Shared";

import { PeriodIcon } from "../assets/icons";
import { FilterValueType } from "../common/types";
import { getDateRangeKey } from "../common/utils";
import { CustomSelect } from "./custom-select";

type Props<T> = {
  field: keyof T;
  rangeDateVariants?: PredefinedRanges[];
};

export const DateFilter = <T,>({
  field,
  rangeDateVariants = RangeDateVariants,
  ...props
}: Props<T>) => {
  const { isDesktop } = useDimension();
  const { values, setFieldValue, submitForm } =
    useFormikContext<FilterValueType<T>>();

  const debounceSubmitForm = useDelay(submitForm, 300);

  const handleSelectDate = (selectedValue: DateRageFilterType) => {
    setFieldValue(field as string, selectedValue);

    if (isDesktop) {
      debounceSubmitForm();
    }
  };

  const handleSelectRangeChange = (value: PredefinedDateRangeType) => {
    const date = formatDateRange(value, formatOfDate);
    handleSelectDate(date);
  };

  const defaultValue = React.useMemo(() => {
    const value = values[field];

    if (Array.isArray(value) && value.length === 2) {
      return { value: getDateRangeKey(value, formatOfDate) };
    }

    return rangeDateVariants[0];
  }, [values, field]);

  return (
    <DateRangeSelect
      maxDate={new Date()}
      dateRange={values[field] as unknown as DateRageFilterType}
      defaultSelected={defaultValue as PredefinedRanges}
      predefinedRanges={rangeDateVariants}
      onChangeDate={handleSelectDate}
      onChangeSelect={({ value }) => handleSelectRangeChange(value)}
      inputComponent={(inputValues) => (
        <CustomSelect
          label={isDesktop ? "" : "Период"}
          icon={<PeriodIcon />}
          {...inputValues}
        />
      )}
      {...props}
    />
  );
};
