import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { ridesService } from "@api/services";
import { REFETCH_INTERVAL } from "@common/Constants";
import { GetRidesFiltersType } from "@common/Types";

export const useGetRides = (params: GetRidesFiltersType) => {
  return useQuery({
    queryKey: [QUERY_KEYS.RIDES, params],
    queryFn: () => ridesService.getRides(params),
    refetchInterval: REFETCH_INTERVAL,
  });
};
