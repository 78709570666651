import { useGetPermissions } from "@api/queries";
import { ROLES } from "@common/Constants";
import { RoleType } from "@common/Types";
import { APP_PERMISSIONS } from "@configs";

import { sidebarRoutes } from "../constants";

/**
 * Custom hook to get filtered routes based on the provided role.
 *
 * @param {Role} role - The role of the user, which determines which permissions and routes are used.
 * @returns {Array} - The filtered routes based on the active permissions for the given role.
 */

export const useGetSidebarRoutes = (role: RoleType) => {
  const getFilteredRoutes = (
    activePageNames: string[],
    key: "renderKey" | "path",
  ) => {
    return sidebarRoutes.map((route) => ({
      ...route,
      routes: route.routes.filter(
        (route) => activePageNames?.includes(route[key]),
      ),
    }));
  };

  if (role === ROLES.DISPATCHER) {
    const { response = [] } = useGetPermissions({});

    const activePageNames = response
      .filter((permission) => permission.is_active)
      .map((permission) => permission.name);

    return getFilteredRoutes(activePageNames, "renderKey");
  }

  const activePageNames = APP_PERMISSIONS.PERMISSIONS[role];

  return getFilteredRoutes(activePageNames, "path");
};
