import { FormikHelpers, useFormikContext } from "formik";
import React from "react";

import { useGetCities } from "@api/queries";
import { useDelay, useDimension } from "@common/Hooks";
import { buildCitiesOption } from "@common/Utils";

import { CityIcon } from "../assets/icons";
import { FilterValueType } from "../common/types";
import { updateChips } from "../common/utils";
import { CustomSelect } from "./custom-select";
import { MultiSelectFilter } from "./multi-select-filter";

type Props<T> = {
  field: keyof T;
  singleChoice?: boolean;
  clearable?: boolean;
  afterChange?: (
    values: FilterValueType<T>,
    formikHelpers: FormikHelpers<FilterValueType<T>>,
  ) => void;
};

export const CitiesFilter = <T,>({
  field,
  singleChoice = true,
  clearable = true,
  afterChange,
  ...props
}: Props<T>) => {
  const { isDesktop } = useDimension();
  const formikHelpers = useFormikContext<FilterValueType<T>>();
  const { values, setValues, submitForm } = formikHelpers;

  const { response: cities } = useGetCities(0, 50);
  const debounceSubmitForm = useDelay(submitForm, 300);

  const options = buildCitiesOption(cities?.items);

  const handleSelectionChange = (selectedValue: string) => {
    const fullValue = options.find((option) => option.value === selectedValue);
    const label = fullValue?.label || "";
    const chips = values.chips || [];

    const updatedChips = updateChips(chips, field as string, label);

    const changedValues = {
      ...values,
      [field]: selectedValue,
      chips: updatedChips,
    };

    setValues(changedValues);

    afterChange?.(changedValues, formikHelpers);

    if (isDesktop) {
      debounceSubmitForm();
    }
  };

  if (!singleChoice) {
    return (
      <MultiSelectFilter
        field={field}
        options={options}
        label="Город"
        placeholder="Город"
        icon={CityIcon}
      />
    );
  }

  return (
    <CustomSelect
      clearable={clearable}
      value={values[field] as unknown as string}
      icon={<CityIcon />}
      label={isDesktop ? "" : "Город"}
      data={options}
      onChange={handleSelectionChange}
      multiple={!singleChoice}
      placeholder="Город"
      {...props}
    />
  );
};
