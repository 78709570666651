import { PARK_STATUSES } from "@common/Constants";

import { IDType } from "./commonTypes";
import { DateRageFilterType } from "./filtersTypes";
import { ParkSettingsType } from "./limitsTypes";
import { AdminCityInfo } from "./zonesTypes";

export type ParksShort = {
  id: number;
  name: string;
  number: number;
  status: string;
};

export type LandingParkApplicationType = {
  name: string;
  company_name: string;
  phone: string;
  email: string;
  city_id: number | string;
  cars_amount: string;
};

export type ParksDetailType = {
  id: IDType;
  address: string;
  cars_amount: number;
  description: string;
  bin: number | string;
  name: string;
  phone: string;
  rating: number;
  status: PARK_STATUSES;
  withdraw_terms: string;
  work_end_time: string;
  work_start_time: string;
  number: string;
  city_id: string | number;
  is_default: boolean | null;
  city?: AdminCityInfo;
  configs: ParkSettingsType;
};

export type ParksType = {
  items: ParksDetailType[];
  length: number;
};

export type ParksActivationType = {
  parkId: IDType;
  status: PARK_STATUSES;
};

export enum PROMOTION_TYPES {
  REFERRAL_PROMOTION = "referral_promocode",
  REGISTRATION_PROMOTION = "registration",
  FIRST_PAYMENT_CARD_SAVE = "first_payment_card_save",
  CASHBACK = "cashback",
  RIDE = "ride",
}

export type ParkFiltersType = {
  city_id?: number;
  search?: string;
};

export type ParkReportFilterType = {
  parks: number[];
  date_range: DateRageFilterType;
};
