import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import React, { memo, useState } from "react";

import { cn } from "@common/Utils";
import { Typography } from "@components/Elements";
import { ImageViewer } from "@components/Shared";

import { GalleryClassnamesType, GalleryView } from "./types";
import { VIEW_CLASSES } from "./variants";

export type GalleryPhotos = {
  photos: string[] | undefined;
  classNames?: Partial<GalleryClassnamesType>;
  view?: GalleryView;
};

export const Gallery: React.FC<GalleryPhotos> = memo(
  ({ photos, classNames, view = "default" }) => {
    const [currentImg, setCurrentImg] = useState(0);
    const [modalShow, setModalShow] = useState(false);

    const openImageViewer = (index: number) => {
      setModalShow(true);
      setCurrentImg(index);
    };

    return photos ? (
      <div className={cn(VIEW_CLASSES[view].wrapper, classNames?.wrapper)}>
        <ImageViewer
          photos={photos}
          show={modalShow}
          setShow={setModalShow}
          currentImg={currentImg}
          setCurrentImg={setCurrentImg}
        />
        <AnimateSharedLayout>
          <AnimatePresence>
            {photos?.map((photo, index) => (
              <motion.img
                key={index}
                src={photo}
                loading="lazy"
                transition={{ ease: "easeOut", duration: 0.3 }}
                onClick={() => openImageViewer(index)}
                animate={{ scale: 1 }}
                alt="drivers photo"
                className={cn(VIEW_CLASSES[view].image, classNames?.image)}
                layoutId={`product-${index}`}
              />
            ))}
          </AnimatePresence>
        </AnimateSharedLayout>
      </div>
    ) : (
      <Typography color={"neutral-800"}>Нет фотографий</Typography>
    );
  },
);
