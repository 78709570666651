import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { TransactionsService } from "@api/services";
import { AdminTransactionsParams } from "@common/Types";

export const useGetAdminTransactions = (filters: AdminTransactionsParams) => {
  return useQuery({
    queryKey: [QUERY_KEYS.ADMIN_TRANSACTIONS, filters],
    queryFn: async () =>
      await TransactionsService.getAdminTransactions(filters),
  });
};
