export const NAVIGATE_ROUTES = {
  MAIN: "/landing",
  PAYMENT_FAILURE: "/payment/failure",
  DRIVERS_LANDING: "/landing/drivers",
  PARTNER_LANDING: "/landing/partners",

  // policies routes
  PRIVACY: "/policies/privacy",

  // faq
  MOBILE_DRIVER_FAQ: "/mobile/driver/faq",
  MOBILE_RIDER_FAQ: "/mobile/rider/faq",

  APP: "/dashboard/",

  LOGIN: "/login/",

  RIDES: "/dashboard/rides",

  RIDES_DETAIL: "/dashboard/rides/:tripId",

  PARKS_APPLICATIONS: "/dashboard/park-applications",

  TRANSACTIONS: "/dashboard/transactions",

  USERS: "/dashboard/users",

  PARKS: "/dashboard/parks",
  PARKS_DETAIL: "/dashboard/parks/:parkId",

  MY_PARK_DETAIL: "/dashboard/parks/my",

  RIDERS: "/dashboard/riders",

  RIDERS_DETAIL: "/dashboard/riders/:riderId",

  REPORT: "/dashboard/report",

  NEWS: "/dashboard/news",

  NEWS_CREATE: "/dashboard/news/create/:type",

  PROMOTIONS: "/dashboard/promotions",

  PROMOTIONS_DETAIL: "/dashboard/promotions/:promotionId",

  DRIVERS: "/dashboard/drivers",

  DRIVERS_DETAIL: "/dashboard/drivers/:id",

  DRIVERS_DETAIL_EDIT: "/dashboard/drivers/:id/edit",

  DASHBOARD: "/dashboard/main/",

  CITIES: "/dashboard/cities",

  CITIES_DETAIL: "/dashboard/cities/:cityId",

  CITIES_CREATE: "/dashboard/cities/create",

  CITIES_DETAIL_EDIT: "/dashboard/cities/:cityId/edit",

  STATISTICS: "/dashboard/statistics",

  SETTINGS: "/dashboard/settings",

  CHAT: "/dashboard/chat",

  APPLICATIONS: "/dashboard/applications",

  APPLICATIONS_DETAIL: "/dashboard/applications/:id",

  PHOTO_CONTROL: "/dashboard/photo-control",

  PHOTO_CONTROL_DETAIL: "/dashboard/photo-control/:id",

  FRAUD_CONTROL: "/dashboard/fraud-control",

  CORPORATE_CLIENTS: "/dashboard/corporate-clients",

  CORPORATE_CLIENTS_DETAIL: "/dashboard/corporate-clients/:clientId",
};

export const ROUTES_RENDER_KEY = {
  DASHBOARD: "main",
  APPLICATIONS: "driver_application_main",
  PARKS_APPLICATIONS: "park_application_main",
  PHOTO_CONTROL: "photo_control_main",
  CITIES: "cities_main",
  FRAUD_CONTROL: "fraud_control_main",
  RIDES: "rides_main",
  DRIVERS: "drivers_main",
  RIDERS: "riders_main",
  CORPORATE_CLIENTS: "corporate_clients_main",
  PARKS: "parks_main",
  TRANSACTIONS: "transaction_main",
  NEWS: "news_main",
  PROMOTIONS: "promotion_main",
  STATISTICS: "statistics_main",
  REPORT: "cbk_report",
  CHAT: "chat",
  SETTINGS: "settings_main",
  USERS: "admins_main",
  DRIVERS_DETAIL_EDIT: "driver_profile_update",
};

export const MAIN_ROUTES = {
  LOGIN: {
    root: "/login",
  },
  DELETED_ACCOUNT: {
    root: "/delete-account",
  },
  MAIN: {
    root: "/landing",
    card_link_failure: "/card-failure",
    card_link_success: "/card-success",
    drivers: "/drivers",
    old_license: "/license",
    old_privacy: "/privacy",
    old_term_of_use: "/termofuse",
    old_public_offer: "/public-offer",
    partners: "/partners",
  },
  POLICIES: {
    root: "/policies",
    license: "/license/:lang",
    privacy: "/privacy/:lang",
    public_offer: "/public-offer/:lang",
    term_of_use: "/term-of-use/:lang",
  },
  PAYMENTS: {
    root: "/payment",
    success: "/success",
    failure: "/failure",
  },
  MOBILE: {
    root: "/mobile",
    driver_faq: "/driver/faq",
    rider_faq: "/rider/faq",
    payment_procedure: "/payment-procedure",
  },
  DEEPLINK: {
    root: "/deeplink",
    rider_promo: "/rider/promo/:promo",
    driver_promo: "/driver/promo/:promo",
  },
  // DASHBOARD
  DASHBOARD: {
    MAIN: {
      root: "main",
    },
    APPLICATIONS: {
      root: "applications",
      detail: "/:id",
    },
    RIDERS: {
      root: "riders",
      detail: "/:riderId",
    },
    DRIVERS: {
      root: "drivers",
      detail: "/:id",
      edit: "/:id/edit",
    },
    RIDES: {
      root: "rides",
      detail: "/:rideId",
    },
    CITIES: {
      root: "cities",
      detail: "/:cityId",
      create: "/create",
      edit: "/:cityId/edit",
    },
    PARKS: {
      root: "parks",
      detail: "/:parkId",
      my: "/my",
    },
    PARK_APPLICATIONS: {
      root: "park-applications",
    },
    CORPORATE_CLIENTS: {
      root: "corporate-clients",
      detail: "/:id",
    },
    PHOTO_CONTROL: {
      root: "photo-control",
      detail: "/:id",
    },
    PROMOTION: {
      root: "promotions",
      detail: "/:id",
    },
    FRAUD_CONTROL: {
      root: "fraud-control",
    },
    CHAT: {
      root: "chat",
    },
    NEWS: {
      root: "news",
    },
    REPORT: {
      root: "report",
    },
    TRANSACTION: {
      root: "transactions",
    },
    USERS: {
      root: "users",
    },
    SETTINGS: {
      root: "settings",
    },
    STATISTICS: {
      root: "statistics",
    },
  },
};
