import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { parksService } from "@api/services";
import { ParkFiltersType, ParksType, QueryParams } from "@common/Types";

export const useGetParks = (params: QueryParams<ParkFiltersType>) => {
  return useQuery<ParksType>({
    queryKey: [QUERY_KEYS.PARKS, params],
    queryFn: async () => await parksService.getParks(params),
  });
};
