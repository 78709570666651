import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { parksService } from "@api/services";
import { PARK_STATUSES } from "@common/Constants";
import { queryClient } from "@common/Libs/react_query";
import { ParksActivationType } from "@common/Types";

export const useSetParkActivation = () => {
  return useMutation({
    mutationFn: async (params: ParksActivationType) =>
      await parksService.setParkActivation(params),

    onSuccess: (_, { status }) => {
      toast.success(
        `Таксопарк ${
          status === PARK_STATUSES.ACTIVE ? "активирован" : "деактивирован"
        }`,
      );

      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PARKS_DETAIL] });
    },
  });
};
