import { Select, SelectProps } from "@mantine/core";
import React from "react";

import { cn } from "@common/Utils";

export const CustomSelect = (props: SelectProps) => {
  return (
    <Select
      className={cn("w-full", props.className)}
      classNames={{
        ...props.classNames,
        input:
          "!h-[40px] border text-body-1-a !border-neutral-border-300 !rounded-s placeholder-neutral-400 focus:shadow-none",
      }}
      {...props}
    />
  );
};
