import { useFormikContext } from "formik";
import React from "react";

import { useGetParksShort } from "@api/queries";
import { useDelay, useDimension } from "@common/Hooks";
import { FilterTabType, ParksShort } from "@common/Types";

import { ParkIcon } from "../assets/icons";
import { FilterValueType } from "../common/types";
import { updateChips } from "../common/utils";
import { CustomSelect } from "./custom-select";
import { MultiSelectFilter } from "./multi-select-filter";

type Props<T> = {
  field: keyof T;
  selectedCity?: string | number;
  clearable?: boolean;
  singleChoice?: boolean;
};

const buildParksFilter = (parks: ParksShort[]): FilterTabType[] => {
  return parks.map((park) => ({
    label: park.name,
    value: park.id.toString(),
  }));
};

export const ParksFilter = <T,>({
  field,
  selectedCity,
  singleChoice = false,
  clearable = true,
  ...props
}: Props<T>) => {
  const { isDesktop } = useDimension();

  const { values, setValues, submitForm } =
    useFormikContext<FilterValueType<T>>();

  const debounceSubmitForm = useDelay(submitForm, 300);
  const { data: parksShorts = [] } = useGetParksShort(selectedCity);

  const options = buildParksFilter(parksShorts);

  const handleSelectionChange = (selectedValue: string) => {
    const fullValue = options.find((option) => option.value === selectedValue);
    const label = fullValue?.label || "";
    const chips = values.chips || [];

    const updatedChips = updateChips(chips, field as string, label);

    setValues({
      ...values,
      chips: updatedChips,
      [field]: selectedValue,
    });

    if (isDesktop) {
      debounceSubmitForm();
    }
  };

  if (!singleChoice) {
    return (
      <MultiSelectFilter
        field={field}
        options={options}
        label="Таксопарк"
        placeholder="Таксопарк"
        icon={ParkIcon}
      />
    );
  }

  const value = values[field] ? (values[field] as unknown as string) : null;
  return (
    <CustomSelect
      data={options}
      onChange={handleSelectionChange}
      value={value}
      clearable={clearable}
      label={isDesktop ? "" : "Таксопарк"}
      icon={<ParkIcon />}
      placeholder="Таксопарк"
      {...props}
    />
  );
};
