import { showNotification } from "@mantine/notifications";
import EventEmitter from "events";
import React, { createContext, useEffect, useState } from "react";

import { buildWSS } from "@api/config";
import { CHAT_EVENT_KEY, ROLES } from "@common/Constants";
import { useSelectedChat } from "@common/Hooks";
import uiEvents from "@common/Libs/events";
import { RoleType, WsMessage, WsMessageDataType } from "@common/Types";
import {
  getAccessTokenLocalStorage,
  getLinkToChatByMessageSender,
  getUserRole,
  notificationSound,
  parseChatSender,
} from "@common/Utils";

const MAX_RECONNECT_COUNT = 5;

interface ISocketProvider {
  children: React.ReactNode;
}

interface SocketContextType {
  observer: EventEmitter;
  online: boolean;
  sendMessage?: (message: string) => void;
}

export const SocketContext = createContext<SocketContextType>({
  observer: uiEvents,
  online: false,
});

const getChatMessagePreview = (message: WsMessageDataType) => {
  const previews = {
    text: message.message,
    image: "Прикрепленное изображение",
  };

  return previews[message.type] || "";
};

export const SocketProvider = ({ children }: ISocketProvider) => {
  const userRole = getUserRole() as RoleType;
  const accessToken = getAccessTokenLocalStorage();

  const { selectedChat } = useSelectedChat();

  const [isWsOnline, setIsWsOnlineState] = useState(false);
  const [reconnectCount, setReconnectCount] = useState(0);
  const [newMessage, setNewMessage] = useState<WsMessage | null>(null);

  const socket = React.useMemo(() => {
    if (accessToken) {
      return buildWSS(accessToken);
    }
  }, [accessToken, reconnectCount]);

  const sendMessage = (message: string) => {
    if (socket) socket.send(message);
  };

  const toChatPage = ({ phone = "", sender }: WsMessage["data"]) => {
    window.open(getLinkToChatByMessageSender(sender, phone), "_blank");
  };

  const handleMessage = (message: WsMessage) => {
    const { sender, driver_id, rider_id } = message.data;

    const { member_id } = selectedChat || {};

    const PERMITTED_ROLES = new Set<RoleType>([
      ROLES.SUPERADMIN,
      ROLES.CITYSUPERADMIN,
      ROLES.DISPATCHER,
    ]);

    const hasPermission = (sender: string, userRole: RoleType) => {
      const permission = PERMITTED_ROLES.has(userRole);
      return permission && sender !== "admin";
    };

    const isPermitted = hasPermission(sender, userRole);

    const id = driver_id || rider_id;
    const isChatNotification = id !== member_id;

    if (isChatNotification && isPermitted) {
      showNotification({
        title: (
          <p
            onClick={() => toChatPage(message.data)}
            className="cursor-pointer"
          >
            {parseChatSender(sender)}
          </p>
        ),
        message: (
          <p onClick={() => toChatPage(message.data)} className="line-clamp-2">
            {getChatMessagePreview(message.data)}
          </p>
        ),
        color: "pink",
      });
      notificationSound();
    }
  };

  useEffect(() => {
    if (newMessage) handleMessage(newMessage);
  }, [newMessage]);

  const onRetrievedMessage = (event: MessageEvent) => {
    const message = JSON.parse(event.data);
    const driverMsg = "driver-support-message";
    const riderMsg = "rider-support-message";

    const isDriverMsg = driverMsg === message.type;
    const isRiderMsg = riderMsg === message.type;

    if (isDriverMsg || isRiderMsg) {
      uiEvents.emit(CHAT_EVENT_KEY, message);
      setNewMessage(message);
    }
  };

  const onClose = ({ code, wasClean }: CloseEvent) => {
    setIsWsOnlineState(false);

    if (code === 1006 && !wasClean) {
      setReconnectCount((prev) =>
        prev < MAX_RECONNECT_COUNT ? prev + 1 : prev,
      );
    }
  };

  const onOpen = () => {
    setIsWsOnlineState(true);
  };

  const createConnection = () => {
    if (socket) {
      socket.addEventListener("open", onOpen);
      socket.addEventListener("close", onClose);
      socket.addEventListener("message", onRetrievedMessage);
    }
  };

  const closeConnection = () => {
    if (socket) {
      socket.removeEventListener("open", onOpen);
      socket.removeEventListener("close", onClose);
      socket.removeEventListener("message", onRetrievedMessage);
      socket.close();
    }
  };

  useEffect(() => {
    createConnection();

    return () => {
      closeConnection();
    };
  }, [socket]);

  return (
    <SocketContext.Provider
      value={{
        observer: uiEvents,
        online: isWsOnline,
        sendMessage,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
