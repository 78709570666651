import * as Yup from "yup";

import { ConfigsType } from "@common/Types";
import { APP_COMMON } from "@configs";

export const passwordValidation = Yup.string()
  .required("Обязательное поле")
  .min(8, "Длинна пароля минимум 8 символов")
  .matches(
    /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
    "Пароль должен содержать 1 маленькую и большую букву, цифру и один символ - !@#$%^&",
  );

export const confirmPasswordValidation = Yup.string()
  .required("Обязательное поле")
  .oneOf([Yup.ref("password")], "Пароли должны совпадать");

export const booleanNumberValidation = Yup.number()
  .required("Обязательное поле")
  .min(0, "Значение не может быть меньше 0")
  .max(1, "Значение не может быть больше 1");

export const numberValidation = Yup.number()
  .required("Обязательное поле")
  .typeError("Значение должно быть числом")
  .min(0, "Число не может быть меньше 0")
  .max(9999999999, "Число не может быть больше 9999999999");

export const offerExpirationValidation = Yup.number()
  .required("Обязательное поле")
  .typeError("Значение должно быть числом")
  .min(10, "Время не может быть меньше 10 сек")
  .max(30, "Время не может быть больше 30 сек");

export const intervalValidation = Yup.number()
  .required("Обязательное поле")
  .typeError("Значение должно быть числом")
  .min(30, "Интервал между предложениями не должен быть меньше 30 сек")
  .max(300, "Интервал между предложениями не должен быть больше 300 сек");

export const quantityLimitValidation = Yup.number()
  .required("Обязательное поле")
  .typeError("Значение должно быть числом")
  .min(1, "Лимит по количеству предложении не должен быть меньше 1")
  .max(20, "Лимит по количеству предложении не должен быть больше 20");

export const textValidation = Yup.string().required("Обязательное поле");

export const phoneValidation = Yup.string()
  .required("Обязательное поле")
  .test({
    name: "phone-test",
    test(value, ctx) {
      const INVALID_LENGTH_MESSAGE = "Некорректная длина";

      const createError = (message: string) =>
        ctx.createError({ path: ctx.path, message });

      const validatePhone = (code: string, length: number) => {
        if (!value.startsWith(code)) {
          return createError(`Формат начала номера телефона: +${code}`);
        }
        if (value.length !== length) {
          return createError(INVALID_LENGTH_MESSAGE);
        }

        return true;
      };

      return validatePhone(APP_COMMON.PHONE.code, APP_COMMON.PHONE.length);
    },
  });

export const getDynamicNumberValidation = (min: number, max: number) =>
  Yup.number()
    .required("Обязательное поле")
    .typeError("Значение должно быть числом")
    .min(min, `Число не должно быть меньше ${min}`)
    .max(max, `Число не должно быть больше ${max}`);

export const getDynamicValidationSchema = (name: keyof ConfigsType) => {
  return Yup.object().shape({
    [name]: Yup.number().required("Обязательное поле"),
  });
};

export const getDynamicValidationArraySchema = (
  names: Array<keyof ConfigsType>,
) => {
  return Yup.object().shape(
    names.reduce((acc: any, name) => {
      acc[name] = Yup.number().required("Обязательное поле");
      return acc;
    }, {}),
  );
};
