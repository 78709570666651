import { TextInput as MantineTextInput, Tooltip } from "@mantine/core";
import { Field, useField } from "formik";
import { isEmpty } from "radash";
import React from "react";

import { TooltipIcon } from "@assets/Icons";

import { Col, Row } from "../flexbox";
import { Typography } from "../typography";
import { TextInputProps } from "./types";
import { VIEW_CLASSES } from "./variants";

/**
 * A customizable text input component.
 *
 * This component wraps Mantine's `TextInput` and applies custom styles based on the `view` prop.
 * It handles different states such as `disabled` and `error`, and allows additional styling through classNames.
 *
 * @param {TextInputProps} props - The properties for the TextInput component.
 * @returns {React.FC} - The TextInput component.
 */

export const TextInput = ({
  view = "default",
  children,
  label,
  required,
  tooltip,
  error,
  ...props
}: TextInputProps) => {
  const {
    opened,
    label: tooltipLabel,
    children: tooltipChildren = <TooltipIcon />,
    ...tooltipProps
  } = tooltip ?? {};

  const isTooltipOpened = opened ?? !isEmpty(tooltip);

  return (
    <Col className="w-full">
      {label && (
        <Row alignItems="items-center" className="inline-flex mb-1">
          <Typography className={VIEW_CLASSES[view].label} as="label">
            {label}

            {required && <span className="text-error ml-1">*</span>}
          </Typography>

          {isTooltipOpened && (
            <Tooltip label={tooltipLabel} className="ml-1" {...tooltipProps}>
              {tooltipChildren}
            </Tooltip>
          )}
        </Row>
      )}

      <MantineTextInput
        error={error}
        classNames={VIEW_CLASSES[error ? "error" : view]}
        {...props}
      >
        {children}
      </MantineTextInput>
    </Col>
  );
};

export const FormTextInput = ({
  field,
  ...rest
}: TextInputProps & { field: string }) => {
  const [fieldProps, { touched, error }] = useField(field);

  return (
    <Field as={TextInput} error={touched && error} {...fieldProps} {...rest} />
  );
};
