import { Transition } from "@headlessui/react";
import { twMerge } from "tailwind-merge";
import React, { Fragment } from "react";

type Props = {
  show: boolean;
  handleDrawer?: () => void;
  className?: string;
  children?: React.ReactNode;
};

export const Drawer: React.FC<Props> = ({
  show,
  handleDrawer,
  className,
  children,
}) => {
  return (
    <Transition className={"absolute"} show={show}>
      <Transition.Child
        as={"div"}
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={`inset-full h-screen w-screen fixed z-40 top-0 left-0 right-0 bottom-0 ${
          !!handleDrawer && "cursor-pointer"
        }`}
        style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
        onClick={handleDrawer}
      />
      <Transition.Child
        as={Fragment}
        enter="transition ease-in-out duration-300 transform opacity"
        enterFrom="opacity-50 translate-x-full"
        enterTo="translate-x-0 opacity-100"
        leave="transition ease-in-out duration-300 transform opacity"
        leaveFrom="translate-x-0 opacity-100"
        leaveTo="translate-x-full opacity-10"
      >
        <div
          className={twMerge(
            "fixed h-screen overflow-auto md:max-w-[510px] w-11/12 right-0 top-0 z-50 mx-auto rounded-l-md p-6 bg-white",
            className,
          )}
          onClick={(event) => event.stopPropagation()}
        >
          {children}
        </div>
      </Transition.Child>
    </Transition>
  );
};
