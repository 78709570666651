import { IDType } from "./commonTypes";

export type PhotoControlItemType = {
  id: IDType;
  driver_first_name: string;
  driver_last_name: string;
  driver_second_name: string;
  driver_phone: string;
  status: string;
  created_at: string;
  city: string;
  reject_reason: null | string;
};

export type PhotoControlListType = {
  length: number;
  items: PhotoControlItemType[];
};

export type PhotoControlLitFilterType = {
  skip?: number;
  limit?: number;
  filters: {
    search?: string;
    status: PHOTO_CONTROL_APPLICATION_STATUSES;
    city_id?: IDType;
  };
};

export enum PHOTO_CONTROL_APPLICATION_STATUSES {
  NEW = "new",
  REJECTED = "rejected",
  APPROVED = "approved",
}

type Tariff = { id: IDType; name: string };

type Driver = {
  driver_id: IDType;
  first_name: string;
  last_name: string;
  second_name: string;
  phone: string;
};

type Car = {
  id: IDType;
  make: string;
  model: string;
  plate_number: string;
  color: string;
  year: number;
  tariff_ids: number[];
  tariffs?: Tariff[];
};

export type PhotoControlDetailType = {
  id?: IDType | null;
  status: PHOTO_CONTROL_APPLICATION_STATUSES;
  created_at: string;
  photos: string[];
  driver_user: Driver;
  car: Car;
};

export type PhotoControlHandleStatusType = {
  id?: IDType | null;
  status:
    | PHOTO_CONTROL_APPLICATION_STATUSES.APPROVED
    | PHOTO_CONTROL_APPLICATION_STATUSES.REJECTED;
  reject_reason?: string;
};
