import { httpClient } from "@common/Libs/axios";
import {
  IDType,
  LandingParkApplicationType,
  ParkFiltersType,
  ParksActivationType,
  ParksDetailType,
  ParksShort,
  ParksType,
  QueryParams,
} from "@common/Types";
import { removeEmptyFields } from "@common/Utils";

import { parksPath } from "./paths";

/**
 * Описание методов parks.
 */

export const parksService = {
  getParksShort: (cityId?: IDType) =>
    httpClient.get<ParksShort[]>(parksPath.parks_short, {
      params: cityId ? { city_id: cityId } : {},
    }),

  sendParkApplication: (application: LandingParkApplicationType) =>
    httpClient.post(parksPath.parks_applications, application),

  getParks: (params: QueryParams<ParkFiltersType>) => {
    const { filters, ...rest } = params;

    return httpClient.get<ParksType>(parksPath.parks, {
      params: removeEmptyFields({ ...filters, ...rest }),
    });
  },

  getParkDetail: (parkId: IDType) =>
    httpClient.get<ParksDetailType>(parksPath.park_detail(parkId)),

  createPark: (park: Partial<ParksDetailType>) =>
    httpClient.post(parksPath.parks, park),

  editPark: (park: Partial<ParksDetailType>) => {
    const { id = "", ...rest } = park;

    return httpClient.put(parksPath.park_detail(id), rest);
  },

  setParkActivation: ({ parkId, status }: ParksActivationType) =>
    httpClient.put(parksPath.park_activation(parkId), { status }),
};
