import { NativeFetcher } from "@api/config";
import { httpClient } from "@common/Libs/axios";
import {
  IDType,
  UserCreateType,
  UserEditType,
  UserPasswordType,
  UsersFilterType,
  UsersResponseType,
  UserStatusType,
  VerifyCodePrams,
} from "@common/Types";
import {
  buildCommonFiltersValues,
  commaSerializer,
  convertPhoneNumber,
  removeEmptyFields,
} from "@common/Utils";

import { usersPath } from "./paths";

/**
 * Описание методов users.
 */

export const usersService = {
  verifyCode: (params: VerifyCodePrams) => {
    return httpClient.post(usersPath.verify_code, {
      code: params.code,
      phone: convertPhoneNumber(params.phone),
    });
  },
  deleteAccount: (accessToken: string) => {
    return NativeFetcher({
      method: "DELETE",
      token: accessToken,
      path: usersPath.delete_account,
    });
  },

  getUsers: (filters: UsersFilterType, skip: number, limit: number) => {
    const buildFilter = buildCommonFiltersValues(filters);

    return httpClient.get<UsersResponseType>(usersPath.admins, {
      params: { limit, skip, ...buildFilter },
      paramsSerializer: commaSerializer,
    });
  },

  createUser: (data: UserCreateType) => {
    const filteredData = removeEmptyFields(data);

    return httpClient.post<UserCreateType, UserCreateType>(
      usersPath.admin,
      filteredData,
    );
  },

  editUser: (id: IDType, data: UserEditType) => {
    const filteredData = removeEmptyFields(data);

    return httpClient.put<UserEditType, UserEditType>(
      usersPath.editAdmin(id),
      filteredData,
    );
  },

  editUserStatus: (id: IDType, data: UserStatusType) => {
    return httpClient.put<UserStatusType, UserStatusType>(
      usersPath.editAdminStatus(id),
      data,
    );
  },

  editUserPassword: (id: IDType, data: UserPasswordType) => {
    return httpClient.put<void, UserPasswordType>(
      usersPath.editAdminPassword(id),
      data,
    );
  },
};
