import { TARGETS } from "../targets";
import { PERMISSIONS } from "./permissions";

export const PERMISSION_CONFIGS = {
  [TARGETS.MOTOR]: {
    PERMISSIONS: PERMISSIONS,
  },
  [TARGETS.NAVI]: {
    PERMISSIONS: PERMISSIONS,
  },
};
