import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { TransactionsService } from "@api/services";
import { queryClient } from "@common/Libs/react_query";
import { BankAccountBalanceParams } from "@common/Types";

export const useSetBankAccountBalance = () => {
  return useMutation({
    mutationFn: async (params: BankAccountBalanceParams) =>
      await TransactionsService.setBankAccountBalance(params),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ADMIN_TRANSACTIONS_BANK_ACCOUNTS],
      });

      toast.success("Электронный кошелёк успешно пополнен");
    },
    retry: 0,
  });
};
