import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { photoControlService } from "@api/services";
import { PhotoControlHandleStatusType } from "@common/Types";

export const useSetPhotoControlStatus = () => {
  return useMutation({
    mutationFn: (params: PhotoControlHandleStatusType) =>
      photoControlService.setPhotoControlStatus(params),
    onSuccess: (_, variables) => {
      toast.success(
        `Фото контроль ${
          (variables.status === "approved" && "одобрен") ||
          (variables.status === "rejected" && "отклонен")
        }`,
      );
    },
  });
};
