import * as Yup from "yup";

import { INN_LIMIT } from "@common/Constants";
import { phoneValidation, textValidation } from "@common/Validation";

export const DriverRegistrationDataSchemaSteps = [
  Yup.object().shape({
    city_id: textValidation,
    first_name: textValidation,
    last_name: textValidation,
    second_name: Yup.string(),
    phone: phoneValidation,
  }),
  Yup.object().shape({
    iin: Yup.string()
      .required("Обязательное поле")
      .matches(
        new RegExp(`^[0-9]{${INN_LIMIT}}$`),
        `Должно быть ${INN_LIMIT} цифр`,
      ),
    license: Yup.object()
      .shape({
        issue_country: textValidation,
        serial_number: textValidation,
        given_date: textValidation,
        expiration_date: textValidation,
      })
      .required("Обязательное поле"),
    document_images: Yup.object()
      .shape({
        passport_image_id: Yup.mixed().required("Обязательное поле"),
        passport_back_image_id: Yup.mixed().required("Обязательное поле"),
        license_front_image_id: Yup.mixed().required("Обязательное поле"),
        license_back_image_id: Yup.mixed().required("Обязательное поле"),
      })
      .required("Обязательное поле"),
  }),
  Yup.object().shape({
    park_id: Yup.number().required("Обязательное поле"),
    language: textValidation,
    has_car_image: Yup.boolean().required("Обязательное поле"),
    car: Yup.object()
      .shape({
        make: textValidation,
        model: textValidation,
        color: textValidation,
        plate_number: textValidation,
        year: Yup.number().required("Обязательное поле"),
      })
      .required("Обязательное поле"),
    car_images: Yup.string().when("has_car_image", {
      is: false,
      then: () =>
        Yup.object()
          .shape({
            front_seat_image_id: Yup.mixed().required("Обязательное поле"),
            car_trunk_image_id: Yup.mixed().required("Обязательное поле"),
            back_seat_image_id: Yup.mixed().required("Обязательное поле"),
            left_side_image_id: Yup.mixed().required("Обязательное поле"),
            right_side_image_id: Yup.mixed().required("Обязательное поле"),
            back_side_image_id: Yup.mixed().required("Обязательное поле"),
            front_side_image_id: Yup.mixed().required("Обязательное поле"),
          })
          .required("Обязательное поле"),
      otherwise: () => Yup.object().nullable(),
    }),
    has_brand: Yup.boolean().required("Обязательное поле"),
    car_class: Yup.number().required("Обязательное поле"),
    tariff_ids: Yup.array()
      .of(Yup.number().required("Обязательное поле"))
      .required("Обязательное поле")
      .min(1, "Обязательное поле"),
  }),
];

export const driverGroupBalanceValidationSchema = Yup.object().shape({
  amount: Yup.number()
    .required("Укажите сумму транзакции")
    .positive("Не может быть ниже 0")
    .typeError("Только цифры"),
  category: Yup.string().required("Выберите тип"),
  type: Yup.string().required("Выберите тип транзакции"),
  ids: Yup.array().of(textValidation).min(1, "Введите хотя бы один ID"),
  description: textValidation,
  promotion_id: Yup.number().typeError("ID должен быть числом"),
  date_range: Yup.tuple([
    Yup.date().nullable(),
    Yup.date().nullable(),
  ]).required("Обязательное поле"),
});

export const balanceValidationSchema = Yup.object().shape({
  amount: Yup.number()
    .required("Укажите сумму транзакции")
    .positive("Не может быть ниже 0")
    .typeError("Только цифры"),
  type: Yup.string().required("Выберите тип транзакции"),
  description: textValidation,
});

export const activationValidationSchema = (activated: boolean) =>
  Yup.object().shape({
    comment: Yup.string().when([], {
      is: () => activated,
      then: (schema) =>
        schema
          .required("Обязательное поле")
          .max(500, "Описание не должно превышать 500 символов"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

export const driverEditSchema = Yup.object().shape({
  first_name: Yup.string().required("Введите имя"),
  last_name: Yup.string().required("Введите фамилию"),
  car_make: textValidation,
  car_model: textValidation,
  car_plate_number: textValidation,
  car_color: Yup.string().required("Выберите цвет машины"),
  car_class: Yup.number().required("Выберите цвет машины"),
  car_year: Yup.string()
    .required("Обязательное поле")
    .matches(/^[0-9]+$/, "Только цифры для года машины")
    .min(4, "Некорректная дата")
    .max(4, "Некорректная дата"),
  tariff_ids: Yup.array()
    .of(Yup.string())
    .required("Обязательное поле")
    .min(1, "Нужно выбрать как минимум один тариф"),
  issue_country: Yup.string(),
  serial_number: Yup.string(),
  park_id: textValidation,
  issue_date: Yup.string().nullable(),
  expired_date: Yup.string().nullable(),
  iin: Yup.string()
    .length(INN_LIMIT, `Должно быть ${INN_LIMIT} цифр`)
    .required("Обязательное поле"),
});
