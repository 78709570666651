import React from "react";

import { strokeColorClasses } from "@common/Constants";
import { SVGProps } from "@common/Types";

type Props = SVGProps;

export const UserWithCheckbox = ({ strokeColor = "brand-0" }: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={strokeColorClasses[strokeColor]}
    >
      <path
        d="M12.469 3.02278C13.8327 4.38649 13.8327 6.5975 12.469 7.96121C11.1053 9.32492 8.8943 9.32492 7.53059 7.96121C6.16688 6.5975 6.16688 4.38649 7.53059 3.02278C8.8943 1.65907 11.1053 1.65907 12.469 3.02278"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.363 12.7972C13.064 12.2782 11.54 11.9922 10 11.9922C5.952 11.9922 2 13.9592 2 16.9842V17.9842C2 18.5362 2.448 18.9842 3 18.9842H12.413"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 22C14.239 22 12 19.762 12 17C12 14.296 14.3 11.997 17.004 12C19.764 12.002 22 14.24 22 17C22 19.761 19.762 22 17 22"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2218 15.8906L16.4438 18.6686L14.7778 17.0016"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
