import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { photoControlService } from "@api/services";
import { PhotoControlLitFilterType } from "@common/Types";

type Params = PhotoControlLitFilterType;

export const useGetPhotoControls = (params: Params) => {
  return useQuery({
    queryKey: [QUERY_KEYS.PHOTO_CONTROL, params],
    queryFn: () => photoControlService.getPhotoControlList(params),
  });
};
