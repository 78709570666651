import {
  GalleryView,
  GalleryViewStyles,
} from "@components/Shared/gallery/types";

const common = {
  wrapper: "w-full grid",
  image: "object-cover bg-center w-full rounded-md cursor-pointer",
};

const classes = {
  wrapperDefault: `${common.wrapper} grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2`,
  wrapperGrid: `${common.wrapper} grid-cols-2 gap-1`,
  wrapperDriverApplications: `${common.wrapper} grid-cols-[repeat(auto-fit,_minmax(247px,_247px))] gap-[24px]`,
  imageDefault: `${common.image} h-48`,
  imageDriverApplications: `${common.image} h-[160px] rounded-[4px]`,
};

export const VIEW_CLASSES: Record<GalleryView, GalleryViewStyles> = {
  default: {
    wrapper: classes.wrapperDefault,
    image: classes.imageDefault,
  },
  grid: {
    wrapper: classes.wrapperGrid,
    image: classes.imageDefault,
  },
  "driver-applications": {
    wrapper: classes.wrapperDriverApplications,
    image: classes.imageDriverApplications,
  },
};
