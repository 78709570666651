import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { useGetUserRole } from "@api/queries";
import { sentrySetUser } from "@common/Providers";
import { getAccessTokenLocalStorage } from "@common/Utils";
import { Col, Flexbox } from "@components/Elements";
import { DashboardHeader, DashboardSidebar } from "@components/Shared";

type Props = {
  children?: React.ReactNode;
};

export const DashboardLayout = ({ children }: Props) => {
  const accessToken = getAccessTokenLocalStorage();

  const [isOpenSidebar, setOpenSideBar] = React.useState(false);

  const { data: userInfo } = useGetUserRole({
    token: accessToken || "",
    enabled: !!accessToken,
  });

  useEffect(() => {
    if (userInfo) {
      sentrySetUser({
        id: userInfo.data.id,
        userRole: userInfo.data.role,
      });
    }
  }, [userInfo]);

  const handleSidebar = () => {
    setOpenSideBar(!isOpenSidebar);
  };


  return (
    <Flexbox
      flexDirection="flex-row"
      alignItems="items-stretch"
      className="flex h-screen overflow-hidden"
    >
      <DashboardSidebar isOpen={isOpenSidebar} setOpen={handleSidebar} />
      <Col className="flex-1 w-full">
        <DashboardHeader handleSidebar={handleSidebar} />
        <Flexbox
          as="main"
          flexDirection="flex-col"
          alignItems="items-stretch"
          className="flex-auto w-full py-[24px] px-[24px] overflow-x-auto"
        >
          {children ? children : <Outlet />}
        </Flexbox>
      </Col>
    </Flexbox>
  );
};
