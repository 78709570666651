import { ROLES } from "@common/Constants/ENUMS";
import { NAVIGATE_ROUTES } from "@common/Constants/ROUTES";

export const PERMISSIONS = {
  [ROLES.SUPERADMIN]: [
    NAVIGATE_ROUTES.DASHBOARD,
    NAVIGATE_ROUTES.APPLICATIONS,
    NAVIGATE_ROUTES.APPLICATIONS_DETAIL,
    NAVIGATE_ROUTES.PARKS_APPLICATIONS,
    NAVIGATE_ROUTES.PARKS,
    NAVIGATE_ROUTES.PARKS_DETAIL,
    NAVIGATE_ROUTES.PHOTO_CONTROL,
    NAVIGATE_ROUTES.PHOTO_CONTROL_DETAIL,
    NAVIGATE_ROUTES.CITIES,
    NAVIGATE_ROUTES.CITIES_CREATE,
    NAVIGATE_ROUTES.CITIES_DETAIL,
    NAVIGATE_ROUTES.FRAUD_CONTROL,
    NAVIGATE_ROUTES.CITIES_DETAIL_EDIT,
    NAVIGATE_ROUTES.RIDES,
    NAVIGATE_ROUTES.RIDES_DETAIL,
    NAVIGATE_ROUTES.DRIVERS,
    NAVIGATE_ROUTES.DRIVERS_DETAIL,
    NAVIGATE_ROUTES.DRIVERS_DETAIL_EDIT,
    NAVIGATE_ROUTES.RIDERS,
    NAVIGATE_ROUTES.RIDERS_DETAIL,
    NAVIGATE_ROUTES.CORPORATE_CLIENTS,
    NAVIGATE_ROUTES.CORPORATE_CLIENTS_DETAIL,
    NAVIGATE_ROUTES.TRANSACTIONS,
    NAVIGATE_ROUTES.NEWS,
    NAVIGATE_ROUTES.NEWS_CREATE,
    NAVIGATE_ROUTES.STATISTICS,
    NAVIGATE_ROUTES.REPORT,
    NAVIGATE_ROUTES.SETTINGS,
    NAVIGATE_ROUTES.CHAT,
    NAVIGATE_ROUTES.USERS,
    NAVIGATE_ROUTES.PROMOTIONS,
    NAVIGATE_ROUTES.PROMOTIONS_DETAIL,
  ],
  [ROLES.CITYSUPERADMIN]: [
    NAVIGATE_ROUTES.DASHBOARD,
    NAVIGATE_ROUTES.APPLICATIONS,
    NAVIGATE_ROUTES.APPLICATIONS_DETAIL,
    NAVIGATE_ROUTES.PARKS_APPLICATIONS,
    NAVIGATE_ROUTES.PARKS,
    NAVIGATE_ROUTES.PARKS_DETAIL,
    NAVIGATE_ROUTES.PHOTO_CONTROL,
    NAVIGATE_ROUTES.PHOTO_CONTROL_DETAIL,
    NAVIGATE_ROUTES.CITIES,
    NAVIGATE_ROUTES.CITIES_DETAIL,
    NAVIGATE_ROUTES.FRAUD_CONTROL,
    NAVIGATE_ROUTES.RIDES,
    NAVIGATE_ROUTES.RIDES_DETAIL,
    NAVIGATE_ROUTES.DRIVERS,
    NAVIGATE_ROUTES.DRIVERS_DETAIL,
    NAVIGATE_ROUTES.DRIVERS_DETAIL_EDIT,
    NAVIGATE_ROUTES.RIDERS,
    NAVIGATE_ROUTES.RIDERS_DETAIL,
    NAVIGATE_ROUTES.TRANSACTIONS,
    NAVIGATE_ROUTES.NEWS,
    NAVIGATE_ROUTES.NEWS_CREATE,
    NAVIGATE_ROUTES.STATISTICS,
    NAVIGATE_ROUTES.REPORT,
    NAVIGATE_ROUTES.CHAT,
    NAVIGATE_ROUTES.USERS,
  ],
  [ROLES.DISPATCHER]: [], // get from api
  [ROLES.PARKADMIN]: [
    NAVIGATE_ROUTES.DRIVERS_DETAIL,
    NAVIGATE_ROUTES.DRIVERS,
    NAVIGATE_ROUTES.MY_PARK_DETAIL,
    NAVIGATE_ROUTES.RIDES_DETAIL,
    NAVIGATE_ROUTES.PARKS_DETAIL,
    NAVIGATE_ROUTES.RIDES,
  ],
  [ROLES.RIDER]: [],
  [ROLES.DRIVER]: [],
};
