import { NumberInputView, NumberInputViewStyles } from "./types";

const size = {
  default: "h-[40px] py-[8px] px-[14px]",
};

const font = {
  color: "text-neutral-text-800",
  placeholder: "placeholder-neutral-text-400",
  size: "text-body-1-a",
};

const common = {
  focus: "focus:border-brand-border-500",
  focusError: "focus:border-negative-border-500",
};

const classes = {
  root: "w-full",
  inputDefault: `shadow-none border border-neutral-border-300 ${font.color} ${font.placeholder} ${font.size} ${common.focus} ${size.default}`,
  inputNoBorder: `shadow-none border border-transparent bg-neutral-bg-50 ${font.color} ${font.placeholder} ${font.size} ${common.focus} ${size.default}`,
  inputError: `shadow-none border border-negative-border-500 ${common.focusError} ${font.color} ${font.placeholder} ${font.size} ${size.default}`,
  inputDisabled: `shadow-none border text-neutral-text-600 opacity-100 bg-neutral-bg-50 ${size.default} ${font.placeholder} ${font.size}`,
  inputNoBorderDisabled: `shadow-none border border-transparent bg-neutral-bg-50 ${font.color} ${font.placeholder} ${font.size} ${size.default}`,
  label: "text-caption-b text-neutral-text-800",
  error: "text-caption-b text-negative-text-500",
  errorDisabled: "text-caption-b text-negative-text-500",
};

export const VIEW_CLASSES: Record<NumberInputView, NumberInputViewStyles> = {
  default: {
    root: classes.root,
    input: classes.inputDefault,
    label: classes.label,
    error: classes.error,
  },
  "no-border": {
    root: classes.root,
    input: classes.inputNoBorder,
    label: classes.label,
    error: classes.error,
  },
  error: {
    root: classes.root,
    input: classes.inputError,
    label: classes.label,
    error: classes.error,
  },
  disabled: {
    root: classes.root,
    input: classes.inputDisabled,
    label: classes.label,
    error: classes.errorDisabled,
  },
  "no-border-disabled": {
    root: classes.root,
    input: classes.inputNoBorderDisabled,
    label: classes.label,
    error: classes.errorDisabled,
  },
};
