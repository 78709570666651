import { isArray } from "radash";
import React, { useEffect, useState } from "react";

import { CheckIcon, CloseIcon } from "@assets/Icons";
import { useDimension } from "@common/Hooks";
import { cn } from "@common/Utils";

import { Col } from "../flexbox";
import { Popover } from "../popover";
import { TextInput } from "../text-input";
import { Typography } from "../typography";
import { MultipleChoiceDataType, Props } from "./types";

const getValueText = (
  selectedOptions: Set<string>,
  data: MultipleChoiceDataType[],
) => {
  if (!selectedOptions.size) return "";
  if (selectedOptions.size === 1) {
    return (
      data.find(({ value }) => value === [...selectedOptions][0])?.label ||
      "Не выбрано"
    );
  }
  return `Выбрано: ${selectedOptions.size}`;
};

export const GroupedMultiSelect: React.FC<Props> = ({
  data,
  handleSelect,
  selectedValues,
  inputProps,
  ...props
}) => {
  const { isDesktop } = useDimension();
  const [selectedOptions, setSelectedOptions] = useState<Set<string>>(
    new Set(),
  );
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const value = (
      isArray(selectedValues) ? selectedValues : [selectedValues]
    ) as string[];
    setSelectedOptions(new Set(value));
  }, [selectedValues]);

  const groupedOptions = data.reduce<Record<string, MultipleChoiceDataType[]>>(
    (acc, option) => {
      const group = option.group || "__default__";
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(option);
      return acc;
    },
    {},
  );

  const isSingleGroup = Object.keys(groupedOptions).length === 1;

  const handleClear = () => {
    setSelectedOptions(new Set());
    handleSelect([]);
  };

  const handleToggle = (option: MultipleChoiceDataType) => {
    setSelectedOptions((prev) => {
      const newSelection = new Set(prev);

      newSelection.has(option.value)
        ? newSelection.delete(option.value)
        : newSelection.add(option.value);

      handleSelect(data.filter((item) => newSelection.has(item.value)));
      return newSelection;
    });
  };

  const hasOptions = !!Object.entries(groupedOptions).length;
  const value = getValueText(selectedOptions, data);

  return (
    <Popover
      {...props}
      opened={isOpen}
      onClose={() => setIsOpen(false)}
      withArrow
      position="bottom"
      gutter={0}
      zIndex={200}
      className={cn("w-full max-w-[800px]", props.className)}
      classNames={{
        body: isDesktop ? "w-full min-w-[290px]" : "w-[calc(100vw-32px)]",
        arrow: "hidden",
        inner: hasOptions
          ? "w-full max-h-[320px] max-h-[220px] mantine-scrollbar overflow-auto p-2 bg-white border border-gray-300 shadow-lg border-none shadow-none"
          : "hidden",
      }}
      target={
        <TextInput
          {...inputProps}
          value={value}
          readOnly
          onClick={() => setIsOpen((prev) => !prev)}
          rightSection={
            selectedOptions.size > 0 ? (
              <CloseIcon
                size={16}
                onClick={handleClear}
                className="cursor-pointer"
              />
            ) : null
          }
        />
      }
    >
      <div className="relative w-full">
        {Object.entries(groupedOptions).map(([group, options]) => (
          <div key={group} className="w-full">
            {!isSingleGroup && group !== "__default__" && (
              <div className="flex items-center w-full h-8">
                <Typography
                  variant="caption-a"
                  className="whitespace-nowrap pr-2"
                  color="neutral-500"
                >
                  {group}
                </Typography>
                <div className="flex-grow border-b border-gray-300" />
              </div>
            )}
            <Col className="w-full overflow-auto">
              {options.map((option) => {
                const isSelected = selectedOptions.has(option.value);
                return (
                  <label
                    key={option.value}
                    className="w-full h-9 flex items-center justify-between gap-2 cursor-pointer"
                  >
                    <Typography
                      className="w-full truncate"
                      variant="body-2-a"
                      color="neutral-800"
                    >
                      {option.label}
                    </Typography>
                    {isSelected && <CheckIcon />}
                    <input
                      type="checkbox"
                      className="hidden"
                      checked={isSelected}
                      onChange={() => handleToggle(option)}
                    />
                  </label>
                );
              })}
            </Col>
          </div>
        ))}
      </div>
    </Popover>
  );
};
