import { useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import { QUERY_KEYS } from "@api/config";
import { authService } from "@api/services";
import { MeResponse, RoleType } from "@common/Types";
import { setUserRole, showErrorToast } from "@common/Utils";

type Props = {
  token: string;
  enabled?: boolean;
};

export const useGetUserRole = ({ token, enabled = false }: Props) => {
  const { data, isSuccess, isError, error, ...query } = useQuery<
    AxiosResponse<MeResponse>
  >({
    queryKey: [QUERY_KEYS.USER, token],
    queryFn: async () => await authService.getRole(token),
    enabled,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  if (isSuccess) {
    setUserRole(data?.data.role as RoleType);
  }

  if (isError) {
    showErrorToast(error as AxiosError);
  }

  return { ...query, isSuccess, data };
};
