import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { MutableRefObject } from "react";
import type { ControlPosition } from "react-map-gl";
import { useControl } from "react-map-gl";

type Listener = (event?: any) => any;

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition;
  onCreate?: (evt: { features: object[] }) => void;
  onUpdate?: (evt: { features: object[]; action: string }) => void;
  onDelete?: (evt: { features: object[] }) => void;
  drawRef: MutableRefObject<any>;
};

export function DrawControl(props: DrawControlProps) {
  useControl<MapboxDraw>(
    () => {
      const { drawRef, ...otherProps } = props;
      const drawControl = new MapboxDraw(otherProps);
      props.drawRef.current = drawControl;
      return drawControl;
    },
    ({ map }) => {
      map.on("draw.create", props.onCreate as Listener);
      map.on("draw.update", props.onUpdate as Listener);
      map.on("draw.delete", props.onDelete as Listener);
    },
    ({ map }) => {
      map.off("draw.create", props.onCreate);
      map.off("draw.update", props.onUpdate);
      map.off("draw.delete", props.onDelete);
    },
    {
      position: props.position,
    },
  );

  return null;
}
