import { APP_COMMON, APP_DICTIONARY, TARGETS } from "../../configs/index";

export const IS_NAVI = APP_COMMON.TARGET === TARGETS.NAVI;
export const IS_MOTOR = APP_COMMON.TARGET === TARGETS.MOTOR;

export const NAME_RU = APP_DICTIONARY.NAME_RU;
export const COUNTRY = APP_DICTIONARY.COUNTRY;

export const CURRENCY_SYMBOL = APP_COMMON.CURRENCY_SYMBOL;
export const IDENTIFICATION_NUMBER = APP_DICTIONARY.IDENTIFICATION_NUMBER;

export const DISPATCHER_NAME = APP_DICTIONARY.NAME_RU;
export const TABLE_LIMIT = APP_COMMON.TABLE_LIMIT;
export const MOBILE_WIDTH = APP_COMMON.MOBILE_WIDTH as number;
export const DESKTOP_START_WIDTH = APP_COMMON.DESKTOP_START_WIDTH as number;
export const CURRENCY_CODE = APP_COMMON.CURRENCY_CODE;
export const COUNTRY_CODE = APP_COMMON.COUNTRY_CODE;
export const INN_LIMIT = APP_COMMON.INN_LIMIT;
export const REFETCH_INTERVAL = APP_COMMON.REFETCH_INTERVAL;
export const ANDROID_APP_LINK = APP_COMMON.ANDROID_APP_LINK;
export const IOS_APP_LINK = APP_COMMON.IOS_APP_LINK;
export const ANDROID_DRIVER_APP_LINK = APP_COMMON.DRIVER_APP_LINK;
