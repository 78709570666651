import { Menu, Transition } from "@headlessui/react";
import { CaretDown, IdentificationBadge, SignOut } from "phosphor-react";
import React, { Fragment } from "react";

import { useLogout } from "@api/queries";
import { RoleType } from "@common/Types";
import { getUserRole, roleName } from "@common/Utils";

import { ReactComponent as UserCircle } from "../../assets/icons/person_black_24.svg";

const HeaderDropdown = () => (
  <Menu as="div" className="relative inline-block text-left">
    {({ open }) => (
      <>
        <Menu.Button className="flex items-center justify-center space-x-2 w-full rounded-[6px] text-sm text-gray-600 py-[10px] px-[15px] w-[174px] bg-[#F6FAFF] focus:outline-none focus:ring-1 focus:ring-blue-600 focus:ring-offset-2">
          <UserCircle />
          <span className="px-[8px]">Профиль</span>
          <CaretDown
            size={14}
            className={`${open && "rotate-180"} transform transition-transform`}
          />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200 transform"
          enterFrom="opacity-0 -translate-y-2"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-out duration-200"
          leaveFrom="opacity-10"
          leaveTo="opacity-0"
        >
          <Menu.Items className="absolute right-0 w-72 mt-2 origin-top-right bg-white rounded-md shadow-lg focus:outline-none border border-gray-300">
            <div className="">
              <Menu.Item>
                <div className="border-b border-gray-200 p-3">
                  <div className="flex items-center space-x-1">
                    <IdentificationBadge />
                    <p className="text-gray-500 text-sm">Роль</p>
                  </div>
                  <p className="font-medium text-gray-600">
                    {roleName(getUserRole() as RoleType)}
                  </p>
                </div>
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={useLogout}
                    className={`${
                      active ? "text-blue-700" : "text-gray-700"
                    } flex items-center rounded-md w-full text-sm text-blue-600 p-3 space-x-1 hover:underline`}
                  >
                    <SignOut color={"#2564eb"} />
                    <span>Выход</span>
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </>
    )}
  </Menu>
);

export default HeaderDropdown;
