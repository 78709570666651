import React, { ReactNode } from "react";

import { Col, Row, Typography } from "@components/Elements";

type Props = {
  title: string;
  value: number;
  icon: ReactNode;
};

export const Card = ({ title, value, icon }: Props) => (
  <Row className="p-2 bg-neutral-bg-0 shadow-new gap-3 rounded-[8px]">
    <div className="p-2 bg-neutral-bg-75 rounded-lg">{icon}</div>

    <Col className="overflow-hidden">
      <Typography
        variant="body-2-b"
        color="neutral-500"
        className="font-medium"
      >
        {title}
      </Typography>

      <Typography
        variant="body-2-b"
        color="neutral-800"
        className="font-medium"
      >
        {value}
      </Typography>
    </Col>
  </Row>
);
