import React from "react";

import { RideStatusKey } from "@common/Types";

type Props = {
  status: RideStatusKey;
};

export const XCirleIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21Z"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.83 9.17001L9.17004 14.83"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.83 14.83L9.17004 9.17001"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const NotFoundIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.934 11.467V11.467C18.934 15.591 15.591 18.934 11.467 18.934V18.934C7.343 18.934 4 15.591 4 11.467V11.467C4 7.343 7.343 4 11.467 4V4C15.591 4 18.934 7.343 18.934 11.467Z"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 20L16.75 16.75"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.815 9.11899L9.11902 13.815"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.815 13.815L9.11902 9.11899"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DisabledIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.415 20.415L3.586 16.586C3.211 16.211 3 15.702 3 15.172V8.828C3 8.298 3.211 7.789 3.585 7.415L7.414 3.586C7.789 3.211 8.298 3 8.828 3H15.17C15.701 3 16.21 3.211 16.585 3.586L20.413 7.414C20.789 7.79 21 8.3 21 8.831V15.171C21 15.701 20.789 16.209 20.415 16.584L16.586 20.414C16.211 20.789 15.702 21 15.171 21H8.828C8.298 21 7.789 20.789 7.415 20.415V20.415Z"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.75098 18.249L18.249 5.75101"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const XBellIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 11C13.791 11 12 9.209 12 7C12 4.836 13.84 2.998 16.003 3C18.211 3.002 20 4.792 20 7C20 9.209 18.21 11 16 11"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M13.6 21H10.4"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.133 6H12V6C9.239 6 7 8.239 7 11V11V11.032V13.504C7 13.828 6.817 14.123 6.528 14.268L6.025 14.519C5.397 14.834 5 15.476 5 16.178V16.178C5 17.202 5.83 18.032 6.854 18.032H17.146C18.17 18.032 19 17.202 19 16.178V16.178C19 15.476 18.603 14.834 17.975 14.52L17.472 14.269C17.183 14.123 17 13.828 17 13.504V10.871"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.13 8.13L14.87 5.87"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.13 5.87L14.87 8.13"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CheckCircle = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21Z"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 10L11 15L8 12"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const StatusIcons = ({ status }: Props) => {
  switch (status) {
    case "admin_cancelled": {
      return <DisabledIcon />;
    }
    case "ride_completed": {
      return <CheckCircle />;
    }
    case "driver_missed": {
      return <XBellIcon />;
    }
    case "driver_declined": {
      return <XBellIcon />;
    }
    case "rider_cancelled": {
      return <XCirleIcon />;
    }
    case "rider_cancelled_on_ride": {
      return <XCirleIcon />;
    }
    case "driver_not_found": {
      return <NotFoundIcon />;
    }
    default:
      return <CheckCircle />;
  }
};
