import { twMerge } from "tailwind-merge";
import React from "react";

import { LanguageType } from "@common/Types";

type Props = {
  className?: string;
  availableLangs: LanguageType[];
  onChange: (langKey: string) => void;
};

export const MobileMenuLanguageSwitcher = ({
  className,
  availableLangs,
  onChange,
}: Props) => {
  return (
    <div className={twMerge("flex flex-col gap-[16px]", className)}>
      {availableLangs.map((lang) => {
        return (
          <button
            key={lang.value}
            type="button"
            onClick={() => onChange(lang.value)}
            className={"flex items-center"}
          >
            <span className={"text-white font-normal text-[16px]"}>
              {lang.fullName}
            </span>
          </button>
        );
      })}
    </div>
  );
};
