import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";

import {
  AppLandingLogo,
  AppStore,
  MotorBgCover,
  PlayMarket,
} from "@assets/Icons";
import {
  ANDROID_APP_LINK,
  ANDROID_DRIVER_APP_LINK,
  IOS_APP_LINK,
  NAVIGATE_ROUTES,
} from "@common/Constants";

import { getPageName } from "../common/utils";

type Props = {
  page?: "partner" | "driver" | "home";
};

const ActionButtons = ({ page }: Props) => {
  const { t } = useTranslation();

  const AppStoreLogo = <AppStore variant="white" />;
  const PlayMarketLogo = <PlayMarket variant="white" />;

  switch (page) {
    case "home":
      return (
        <>
          <a
            href={ANDROID_APP_LINK}
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            {PlayMarketLogo}
          </a>
          <a
            href={IOS_APP_LINK}
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            {AppStoreLogo}
          </a>
        </>
      );
    case "partner":
      return (
        <>
          <a
            href={ANDROID_APP_LINK}
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            {PlayMarketLogo}
          </a>
          <a
            href={IOS_APP_LINK}
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            {AppStoreLogo}
          </a>
          ;
        </>
      );
    case "driver":
      return (
        <a
          href={ANDROID_DRIVER_APP_LINK}
          className="w-full h-[52px] p-[10px] flex flex-col items-center justify-center text-[16px] font-bold leading-[1] rounded-[12px] mac:w-[342px] capitalize"
          style={{
            background: "linear-gradient(180deg, #53D6E8 0%, #0CC1DA 100%)",
          }}
          target={"_blank"}
          rel={"noreferrer"}
        >
          {t("drivers.become-a-driver-button")}
        </a>
      );
    default:
      return null;
  }
};

export const MotorFooter = () => {
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();

  const page = getPageName(pathname);

  const getShortLanguageCode = (isoLanguageCode: string) => {
    return isoLanguageCode.split("-")[0];
  };

  return (
    <section
      className={clsx(
        "relative w-full h-screen lg:min-h-[600px] flex flex-col items-center justify-center overflow-hidden",
        {
          "bg-custom-black": page !== "driver",
        },
      )}
    >
      <div
        className={
          "w-full md:w-auto flex flex-col items-center justify-center gap-[32px] lg:gap-[40px] z-10"
        }
      >
        <div className={"flex flex-col"}>
          <AppLandingLogo
            className={clsx("h-[38px] lg:h-[60px] w-auto", {
              "fill-white": page === "home" || page === "partner",
              "fill-custom-black": page === "driver",
            })}
          />
        </div>
        <div
          className={
            "container-landing w-full flex items-center justify-center gap-[12px]"
          }
        >
          <ActionButtons page={page} />
        </div>
        <div>
          <NavLink
            to={`${NAVIGATE_ROUTES.PRIVACY}/${getShortLanguageCode(i18n.language)}`}
            className={clsx("flex", {
              "text-white": page === "home" || page === "partner",
              "text-custom-black": page === "driver",
            })}
          >
            {t("policies.privacy-policy")}
          </NavLink>
        </div>
      </div>
      <div className={"absolute z-0 right-[-70vw] lg:right-[-50vh]"}>
        <MotorBgCover
          className={clsx("w-[150vw] lg:w-[120vh] h-auto", {
            "fill-[#2F3437]": page === "home" || page === "partner",
            "fill-[#E2E2E2]": page === "driver",
          })}
        />
      </div>
    </section>
  );
};
