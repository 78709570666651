import React from "react";

export const CommentIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.20035 17.272H5.28035C4.21955 17.272 3.36035 16.4128 3.36035 15.352V5.75203C3.36035 4.69123 4.21955 3.83203 5.28035 3.83203H18.7204C19.7812 3.83203 20.6404 4.69123 20.6404 5.75203V15.352C20.6404 16.4128 19.7812 17.272 18.7204 17.272H12.8404L8.79875 20.8672C8.17955 21.4183 7.20035 20.9786 7.20035 20.1501V17.272Z"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.2002 8.14797H16.8002"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.2002 12.4683H13.9202"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
