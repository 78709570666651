import React, { ReactElement } from "react";

import { PencilBorderedIcon } from "@assets/Icons";
import { Button, Flexbox } from "@components/Elements";

type Props = {
  isEdit: boolean;
  disabled?: boolean;
  loading?: boolean;
  onClose: () => void;
  onOpen: () => void;
  Icon?: ReactElement;
};

export const ButtonActions = ({
  isEdit,
  disabled,
  loading,
  onClose,
  onOpen,
  Icon = <PencilBorderedIcon />,
}: Props) => {
  return isEdit ? (
    <Flexbox flexDirection="flex-col" className="gap-[10px] sm:flex-row">
      <Button
        type="reset"
        view="transparent"
        onClick={onClose}
        className="py-2 rounded-[4px]"
        disabled={disabled}
      >
        Отменить
      </Button>

      <Button
        type="submit"
        className="py-2 rounded-[4px]"
        disabled={disabled}
        loading={loading}
      >
        Сохранить
      </Button>
    </Flexbox>
  ) : (
    <Button type="reset" view="none" onClick={onOpen}>
      {Icon}
    </Button>
  );
};
