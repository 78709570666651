import React from "react";

export const EmojiPickerIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 9C5 8.17 5.67 7.5 6.5 7.5C7.33 7.5 8 8.17 8 9C8 9.83 7.33 10.5 6.5 10.5C5.67 10.5 5 9.83 5 9ZM10 17C12.33 17 14.31 15.54 15.11 13.5H4.89C5.69 15.54 7.67 17 10 17ZM13.5 10.5C14.33 10.5 15 9.83 15 9C15 8.17 14.33 7.5 13.5 7.5C12.67 7.5 12 8.17 12 9C12 9.83 12.67 10.5 13.5 10.5ZM20 0.5H18V2.5H16V4.5H18V6.5H20V4.5H22V2.5H20V0.5ZM18 11.5C18 15.92 14.42 19.5 10 19.5C5.58 19.5 2 15.92 2 11.5C2 7.08 5.58 3.5 10 3.5C11.46 3.5 12.82 3.9 14 4.58V2.34C12.77 1.8 11.42 1.5 9.99 1.5C4.47 1.5 0 5.98 0 11.5C0 17.02 4.47 21.5 9.99 21.5C15.52 21.5 20 17.02 20 11.5C20 10.45 19.83 9.45 19.53 8.5H17.4C17.78 9.43 18 10.44 18 11.5Z"
        fill="#AEB1BB"
      />
    </svg>
  );
};
