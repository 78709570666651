/**
 * Описание методов rides.
 */

import qs from "query-string";

import { httpClient } from "@common/Libs/axios";
import {
  GetRidesFiltersType,
  IDType,
  QueryParams,
  RefundPaymentQueryParams,
  RideDetailType,
  RideEventType,
  RidesReviewFiltersType,
  RidesReviewType,
  RidesType,
} from "@common/Types";
import { commaSerializer, removeEmptyFilterValues } from "@common/Utils";

import { ridesPath } from "./path";

/**
 * Описание методов rides.
 */

export const ridesService = {
  getRides: (params: GetRidesFiltersType) => {
    return httpClient.get<RidesType>(ridesPath.rides, {
      params: removeEmptyFilterValues(params),
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "none" }),
    });
  },

  getRidesDetail: (rideId?: IDType) =>
    httpClient.get<RideDetailType>(ridesPath.rides_detail(rideId)),
  getRidesReview: (params: QueryParams<RidesReviewFiltersType>) => {
    const { filters, ...rest } = params;

    return httpClient.get<RidesReviewType>(ridesPath.rides_review, {
      params: removeEmptyFilterValues({ ...filters, ...rest }),
      paramsSerializer: commaSerializer,
    });
  },
  getRidesEvents: (rideId?: IDType) =>
    httpClient.get<RideEventType[]>(ridesPath.rides_events(rideId)),

  refundPayment: (params: RefundPaymentQueryParams) => {
    const { rideId, ...rest } = params;

    return httpClient.post(ridesPath.refund_payment(rideId), rest);
  },
  getRideDetail: (ride_id: IDType) =>
    httpClient.get<RideDetailType>(ridesPath.ride_detail(ride_id)),
  cancelRide: (ride_id: IDType) =>
    httpClient.post(ridesPath.cancel_ride(ride_id)),
  setStatusToExpectingRider: (ride_id: IDType) =>
    httpClient.post(ridesPath.set_status_to_rider(ride_id)),
  start_ride: (ride_id: IDType) => httpClient.post(ridesPath.start(ride_id)),
  finish_ride: (ride_id: IDType) => httpClient.post(ridesPath.finish(ride_id)),
};
