import { TARGETS } from "../targets";

export const DICTIONARY_CONFIGS = {
  [TARGETS.MOTOR]: {
    COUNTRY: "Казахстан",
    IN_LANG: "На казахском",
    NAME_RU: "Мотор",
    IDENTIFICATION_NUMBER: "ИИН",
  },
  [TARGETS.NAVI]: {
    COUNTRY: "Кыргызстан",
    IN_LANG: "На кыргызском",
    NAME_RU: "Нави",
    IDENTIFICATION_NUMBER: "ИНН",
  },
};
