import { SelectView, SelectViewStyles } from "./types";

const size = {
  default: "h-[40px] py-[8px] px-[14px]",
};

const font = {
  color: "text-neutral-text-800",
  placeholder: "placeholder-neutral-text-300",
  size: "text-body-1-a",
};

const common = {
  focus: "focus:border-brand-border-500",
  focusError: "focus:border-negative-border-500",
  input: "shadow-none border",
};

const disabledStyles =
  "disabled:placeholder:text-neutral-text-200 disabled:text-neutral-text-200 disabled:opacity-100 disabled:bg-neutral-bg-50";
const baseInput = `${common.input} ${font.color} ${font.placeholder} ${font.size} ${size.default} ${disabledStyles}`;

const classes = {
  root: "w-full",
  inputDefault: `border-neutral-border-300 ${common.focus} ${baseInput}`,
  inputNoBorder: `border-transparent bg-neutral-bg-50 ${common.focus} ${baseInput}`,
  inputError: `border-negative-border-500 ${common.focusError} ${baseInput}`,
  label: "text-caption-b text-neutral-text-800 font-medium",
  icon: "mr-[6px]",
  error: "text-caption-b text-negative-text-500",
};

export const SelectClasses: Record<SelectView, SelectViewStyles> = {
  default: {
    root: classes.root,
    input: classes.inputDefault,
    label: classes.label,
    error: classes.error,
    icon: classes.icon,
  },
  "no-border": {
    root: classes.root,
    input: classes.inputNoBorder,
    label: classes.label,
    error: classes.error,
    icon: classes.icon,
  },
  error: {
    root: classes.root,
    input: classes.inputError,
    label: classes.label,
    error: classes.error,
    icon: classes.icon,
  },
};
