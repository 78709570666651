import { Form, Formik, FormikHelpers } from "formik";
import React from "react";

import { useDimension } from "@common/Hooks";
import { cn } from "@common/Utils";

import { FilterValueType } from "./common/types";
import { CheckboxFilter } from "./components/checkbox-filter";
import { ChipsFilter } from "./components/chips-filter";
import { CitiesFilter } from "./components/cities-filter";
import { DateFilter } from "./components/date-filter";
import { DesktopFilter } from "./components/desktop-filter";
import { MobileFilter } from "./components/mobile-filter";
import { MultiSelectFilter } from "./components/multi-select-filter";
import { ParksFilter } from "./components/park-filters";
import { PaymentTypeFilter } from "./components/payment-type-filter";
import { RightGroup } from "./components/right-group";
import { SearchFilters } from "./components/search-filter";
import { SelectFilter } from "./components/select-filter";
import { StatusFilter } from "./components/status-filter";
import { TariffsFilter } from "./components/tariff-filter";

type Props<T extends object> = {
  initialValues: FilterValueType<T>;
  children: React.ReactNode | React.ReactNode[];
  onSubmit: (
    values: FilterValueType<T>,
    formikHelpers: FormikHelpers<FilterValueType<T>>,
  ) => void;
  className?: string;
};

export const Filters = <T extends object>({
  initialValues,
  onSubmit,
  className,
  children,
}: Props<T>) => {
  const { isDesktop } = useDimension();

  const Component = React.useMemo(
    () => (isDesktop ? DesktopFilter : MobileFilter),
    [isDesktop],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <Form className={cn("w-full mb-6", className)}>
        <Component>{children}</Component>
      </Form>
    </Formik>
  );
};

Filters.Search = SearchFilters;
Filters.RightGroup = RightGroup;
Filters.Cities = CitiesFilter;
Filters.Tariffs = TariffsFilter;
Filters.Parks = ParksFilter;
Filters.Status = StatusFilter;
Filters.Select = SelectFilter;
Filters.Chips = ChipsFilter;
Filters.PaymentType = PaymentTypeFilter;
Filters.Date = DateFilter;
Filters.MultiSelect = MultiSelectFilter;
Filters.Checkbox = CheckboxFilter;
