import { Text } from "@mantine/core";
import React from "react";
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";

import { NewsFormType } from "@common/Types";

import body from "../../../assets/Images/body.jpg";
import info from "../../../assets/Images/info.png";
import { CustomModal } from "./custom-modal";

interface Props {
  opened: boolean;
  modalHandler: () => void;
  values: NewsFormType;
}

export const NewsPreview: React.FC<Props> = ({
  opened,
  modalHandler,
  values,
}) => {
  const truncateText = (limit: number, text: string, more: boolean): string => {
    return text.length > limit
      ? more
        ? text.substring(0, limit) + " <u>Подробнее...</u>"
        : text.substring(0, limit)
      : text;
  };

  // Replace empty body with bordercolor: white
  const transform = (node: any, index: number) => {
    if (node.type === "tag" && node.name === "body") {
      node.attribs.style = `background-color: ${values.background_color}`;
      return convertNodeToElement(node, index, transform);
    }
  };
  const options = {
    decodeEntities: true,
    transform,
  };

  const HtmlParser: any = ReactHtmlParser;

  const truncatedText = truncateText(
    80,
    values.text.replace(/(<([^>]+)>)/gi, ""),
    true,
  );

  return (
    <CustomModal classNames={"z-[100]"} show={opened} close={modalHandler}>
      <div
        className="relative inline-block max-w-xl overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md"
        style={{ width: 375, height: 812 }}
      >
        <div
          className={"absolute top-0 w-full h-[500px]"}
          style={{
            background: `url(${body})`,
            backgroundSize: "cover",
          }}
        />
        <div
          className="absolute bottom-[234px] w-full p-[16px] pb-[40px] border rounded-[16px]"
          style={{
            backgroundColor: values.background_color
              ? values.background_color
              : "white",
          }}
        >
          {values.title ? (
            <div className="relative pr-4 mb-[8px]">
              <Text
                weight={700}
                className={"text-[16px] text-white"}
                align="left"
              >
                {values.title}
              </Text>
              <div className="absolute top-0 right-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM7.17162 5.75737L10.0001 8.58581L12.8284 5.75742C13.219 5.3669 13.8521 5.3669 14.2427 5.75742C14.6332 6.14795 14.6332 6.78111 14.2427 7.17164L11.4143 10L14.2427 12.8284C14.6332 13.219 14.6332 13.8521 14.2427 14.2427C13.8522 14.6332 13.219 14.6332 12.8285 14.2427L10.0001 11.4142L7.17158 14.2427C6.78106 14.6332 6.1479 14.6332 5.75737 14.2427C5.36685 13.8522 5.36685 13.219 5.75737 12.8285L8.58584 10L5.7574 7.17158C5.36688 6.78106 5.36688 6.14789 5.7574 5.75737C6.14793 5.36685 6.78109 5.36685 7.17162 5.75737Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          ) : (
            <span className="text-white italic text-[16px]">
              Заголовок не введен
            </span>
          )}
          {values.text.replace(/(<([^>]+)>)/gi, "").length > 0 ? (
            <Text
              lineClamp={3}
              weight={500}
              align="left"
              className={
                "after:content-['isShow more...'] text-[14px] text-white"
              }
            >
              {truncateText(80, values.text.replace(/(<([^>]+)>)/gi, ""), true)}
              {HtmlParser(truncatedText, options)}
            </Text>
          ) : (
            <p className="text-white italic">Текст не введен</p>
          )}
        </div>
        <div
          className={"absolute bottom-0 w-full h-[258px] z-50"}
          style={{
            background: `url(${info})`,
            backgroundSize: "cover",
          }}
        />
      </div>
    </CustomModal>
  );
};
