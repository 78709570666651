import React from "react";

export const JoystickIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 17.3299V14.6699"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66992 16H9.32992"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.89821 11C6.61821 11 5.33921 11.488 4.36221 12.465C2.40921 14.418 2.40921 17.583 4.36221 19.536C6.31521 21.489 9.48021 21.489 11.4332 19.536H13.5672C15.5202 21.489 18.6852 21.489 20.6382 19.536C22.5912 17.583 22.5912 14.418 20.6382 12.465C19.6622 11.488 18.3822 11 17.1022 11H7.89821V11Z"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.835 16.8169C17.973 16.8169 18.085 16.9289 18.084 17.0669C18.084 17.2049 17.972 17.3169 17.834 17.3169C17.696 17.3169 17.584 17.2049 17.584 17.0669C17.585 16.9289 17.697 16.8169 17.835 16.8169"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7012 14.6831C15.8392 14.6831 15.9512 14.7951 15.9502 14.9331C15.9502 15.0711 15.8382 15.1831 15.7002 15.1831C15.5622 15.1831 15.4502 15.0711 15.4502 14.9331C15.4512 14.7951 15.5632 14.6831 15.7012 14.6831"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 11V9.5C12.5 8.12 13.619 7 15 7V7C16.381 7 17.5 5.881 17.5 4.5V3"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
