import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { IconContext, IconProps } from "phosphor-react";
import React from "react";
import { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";

import { ChatProvider, SocketProvider } from "@common/Providers";

type Props = {
  children: React.ReactNode;
};

const iconDefault: IconProps = {
  size: 18,
  weight: "light",
  mirrored: false,
  color: "#3d444e",
};

const toastOptions = {
  duration: 10000,
  style: {
    maxWidth: "400px",
  },
};

export const DashboardProvidersLayout: React.FC<Props> = ({ children }) => {
  const location = useLocation();

  // Check if the current path starts with "/dashboard"
  const isDashboard = location.pathname.startsWith("/dashboard");

  if (isDashboard) {
    return (
      <MantineProvider
        emotionOptions={{
          key: "mantine",
          prepend: false,
        }}
        theme={{
          fontFamily: "Inter",
          primaryColor: "indigo",
        }}
        styles={{
          Button: () => ({
            root: { fontWeight: 400 },
          }),
          Checkbox: (theme, params) => ({
            input: {
              width: params.size === "sm" ? "18px" : undefined,
              height: params.size === "sm" ? "18px" : undefined,
            },
            inner: {
              width: params.size === "sm" ? "18px" : undefined,
              height: params.size === "sm" ? "18px" : undefined,
            },
          }),
          Radio: (theme, params) => ({
            radio: {
              width: params.size === "sm" ? "18px" : undefined,
              height: params.size === "sm" ? "18px" : undefined,
            },
          }),
          RadioGroup: (theme, params) => ({
            radio: {
              width: params.size === "sm" ? "18px" : undefined,
              height: params.size === "sm" ? "18px" : undefined,
            },
          }),
        }}
      >
        <IconContext.Provider value={iconDefault}>
          <Toaster
            position="bottom-center"
            reverseOrder={false}
            toastOptions={toastOptions}
          />
          <ChatProvider>
            <SocketProvider>
              <NotificationsProvider autoClose={10000} limit={5}>
                {children}
              </NotificationsProvider>
            </SocketProvider>
          </ChatProvider>
        </IconContext.Provider>
      </MantineProvider>
    );
  }

  return (
    <>
      {children}
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        toastOptions={toastOptions}
      />
    </>
  );
};
