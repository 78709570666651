import { DateRangePicker } from "@mantine/dates";
import { endOfDay, startOfDay } from "date-fns";
import { useFormikContext } from "formik";
import { twMerge } from "tailwind-merge";
import React, { FC, ReactElement } from "react";

import { isDayNotMoreOrLessThenSelected } from "@common/Utils";

type Props = {
  name: [string, string];
  label: string;
  placeholder: string;
  maxDaysRange: number;
  icon?: ReactElement;
};

export const ReportRangePicker: FC<Props> = ({
  name: [startName, endName],
  label,
  placeholder,
  maxDaysRange,
  icon,
}) => {
  const { submitCount, setValues, values, errors, touched, setTouched } =
    useFormikContext<Record<string, Date | null>>();
  const isFormSubmitted = submitCount > 0;
  const currentDateString = React.useRef(new Date().toDateString());

  const hasError =
    ((isFormSubmitted || touched[startName]) && errors[startName]) ||
    ((isFormSubmitted || touched[endName]) && errors[endName]);

  const checkDateForExclude = React.useMemo(() => {
    if (maxDaysRange && values[startName]) {
      return isDayNotMoreOrLessThenSelected(
        values[startName] as Date,
        maxDaysRange,
      );
    }
    return (_: Date): boolean => false;
  }, [values[startName], maxDaysRange]);

  return (
    <div className={`w-full relative flex flex-col space-y-1 `}>
      <label className="text-neutral-text-800 text-caption-b font-medium">
        {label}
      </label>

      <div className={"w-full flex flex-col"}>
        <DateRangePicker
          value={[values[startName] ?? null, values[endName] ?? null]}
          onChange={([start, end]) => {
            setValues({
              ...values,
              [startName]: start ? startOfDay(start) : start,
              [endName]: end ? endOfDay(end) : end,
            });
          }}
          placeholder={placeholder}
          nextMonthLabel="Следующий месяц"
          previousMonthLabel="Предыдущий месяц"
          classNames={{
            input: twMerge(
              "h-[42px] text-body-1-a rounded-[4px] border-neutral-border-300 px-[14px] pl-[52px]",
            ),
            icon: "w-[24px] ml-[14px]",
          }}
          excludeDate={checkDateForExclude}
          dayStyle={(date) =>
            date.toDateString() === currentDateString.current
              ? { backgroundColor: "rgb(34, 139, 230)", color: "white" }
              : {}
          }
          amountOfMonths={2}
          locale={"ru"}
          icon={icon}
          onDropdownClose={() => {
            setTimeout(() => {
              setTouched({
                ...touched,
                [startName]: true,
                [endName]: true,
              });
            }, 50);
          }}
          allowSingleDateInRange
          clearable
        />
        {hasError ? (
          <p className="text-negative-bg-500 text-caption-a mt-[5px]">
            {hasError}
          </p>
        ) : null}
      </div>
    </div>
  );
};
