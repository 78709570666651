/**
 * Сервисы для parks
 */

import { appConfig } from "@api/config";
import { IDType } from "@common/Types";

const src = appConfig.api.dashboard;

export const parksPath = {
  parks_short: `${src}/parks-short/`,
  parks_applications: `${src}/parks/applications/`,
  parks: `${src}/parks/`,
  park_detail: (parkId: IDType) => `${src}/parks/${parkId}`,
  park_activation: (parkId: IDType) => `${src}/parks/${parkId}/status/`,
};
