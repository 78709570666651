import { useMutation } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { promotionsService } from "@api/services";
import { queryClient } from "@common/Libs/react_query";
import { IDType } from "@common/Types";

export const useEditArchivePromotionById = () => {
  return useMutation({
    mutationFn: (promotionId: IDType) =>
      promotionsService.archivePromotion(promotionId),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PROMOTIONS] });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROMOTION_DETAILS],
      });
    },
  });
};
