import { Input } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { clsx } from "clsx";
import { useFormikContext } from "formik";
import React from "react";

import { useDelay, useDimension } from "@common/Hooks";

import { MagnifyingGlassIcon } from "../assets/icons";

type Props<T> = {
  field: keyof T;
  rightSection?: React.ReactNode;
  placeholder?: string;
};

export const SearchFilters = <T,>({
  placeholder = "Поиск",
  field,
  rightSection,
  ...props
}: Props<T>) => {
  const { isDesktop } = useDimension();
  const { values, setFieldValue, submitForm } = useFormikContext<T>();
  const [localValue, setLocalValue] = React.useState(
    values[field] as unknown as string,
  );

  const debounceSubmitForm = useDelay(submitForm, 300);
  const [debouncedValue] = useDebouncedValue(localValue, 300);

  React.useEffect(() => {
    setFieldValue(field as string, debouncedValue);

    if (isDesktop) {
      debounceSubmitForm();
    }
  }, [debouncedValue]);

  const onChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(value);
  };

  return (
    <div className={"self-center w-full"} {...props}>
      <Input
        className={clsx("w-full rounded-[4px]")}
        icon={<MagnifyingGlassIcon />}
        classNames={{
          input:
            "h-[40px] border border-neutral-border-300 py-[7px] text-body-1-b focus:shadow-none",
        }}
        placeholder={placeholder}
        value={localValue}
        onChange={onChange}
        rightSection={rightSection}
      />
    </div>
  );
};
