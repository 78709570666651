import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { photoControlService } from "@api/services";
import { IDType } from "@common/Types";

type Params = {
  id: IDType;
};

export const useGetPhotoControlDetails = ({ id }: Params) => {
  return useQuery({
    queryKey: [QUERY_KEYS.PHOTO_CONTROL_DETAILS, id],
    queryFn: () => photoControlService.getPhotoControlDetail(id),
  });
};
