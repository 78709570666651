import React from "react";

import { BankIcon } from "@assets/Icons";
import { BANK_PROVIDER_FILTER_TYPE } from "@common/Constants";

import { CheckboxList } from "../checkbox-list";

type Props<Filters> = {
  className?: string;
  filters: Filters;
  setFilters: (value: Filters) => void;
};

export const BanksFilter = <T,>({ filters, setFilters, className }: Props<T>) => (
  <CheckboxList
    MainIcon={<BankIcon />}
    title="Банк"
    filters={filters}
    setFilters={setFilters}
    field={"provider" as keyof T}
    options={BANK_PROVIDER_FILTER_TYPE}
    className={className}
    singleChoice
  />
);
