import React from "react";

export const MoneyGroupIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 8H20.5C21.605 8 22.5 8.895 22.5 10V20C22.5 21.105 21.605 22 20.5 22H4.5C3.395 22 2.5 21.105 2.5 20V10C2.5 8.895 3.395 8 4.5 8Z"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2678 13.2322C15.2441 14.2085 15.2441 15.7915 14.2678 16.7678C13.2915 17.7441 11.7085 17.7441 10.7322 16.7678C9.75592 15.7915 9.75592 14.2085 10.7322 13.2322C11.7085 12.2559 13.2915 12.2559 14.2678 13.2322"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 17.5V12.5"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 17.5V12.5"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5 5H4.5"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 2H5.5"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
