import React from "react";

import { SVGProps } from "@common/Types";

export const CheckIcon = ({ className }: SVGProps) => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.6668 1.41699L5.50016 10.5837L1.3335 6.41699"
        stroke="#616163"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
    </svg>
  );
};
