import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { driversService } from "@api/services";
import { DriverGroupBalanceType } from "@common/Types";

export const useCreateDriverGroupBalance = () => {
  return useMutation({
    mutationFn: (data: DriverGroupBalanceType) =>
      driversService.createDriverGroupBalance(data),
    onSuccess: () => {
      toast.success("Вы произвели пополнение электронного кошелька");
    },
  });
};
