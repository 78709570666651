import { TARGETS } from "../targets";

export const COMMON_CONFIGS = {
  [TARGETS.MOTOR]: {
    TARGET: TARGETS.MOTOR,
    REFETCH_INTERVAL: 15000,
    TABLE_LIMIT: 15,
    DESKTOP_START_WIDTH: 768,
    MOBILE_WIDTH: 640,
    CURRENCY_SYMBOL: "₸",
    CURRENCY_CODE: "KZT",
    COUNTRY_CODE: "KAZ",
    INN_LIMIT: 12,
    PHONE: {
      code: "7",
      length: 11,
      mask: "+# (###) ### ####",
      maskWithCode: "+7 (###) ### ####",
      placeholder: "+7 (___) ___ ____",
    },
    ANDROID_APP_LINK:
      "https://play.google.com/store/apps/details?id=reviro.taxi.kz",
    IOS_APP_LINK: "https://apps.apple.com/kg/app/motor-taxi/id6450346747",
    DRIVER_APP_LINK:
      "https://play.google.com/store/apps/details?id=reviro.taxi.motor",
  },
  [TARGETS.NAVI]: {
    TARGET: TARGETS.NAVI,
    REFETCH_INTERVAL: 15000,
    TABLE_LIMIT: 15,
    DESKTOP_START_WIDTH: 768,
    MOBILE_WIDTH: 640,
    CURRENCY_SYMBOL: "сом",
    CURRENCY_CODE: "KGZ",
    COUNTRY_CODE: "KGZ",
    INN_LIMIT: 14,
    PHONE: {
      code: "996",
      length: 12,
      mask: "+### (###) ##-##-##",
      maskWithCode: "+996 (###) ##-##-##",
      placeholder: "+996 (___) ___ ____",
    },
    ANDROID_APP_LINK:
      "https://play.google.com/store/apps/details?id=reviro.taxi.rider&hl=ru&gl=US",
    IOS_APP_LINK: "https://apps.apple.com/kg/app/navi/id1585148582",
    DRIVER_APP_LINK:
      "https://play.google.com/store/apps/details?id=reviro.taxi.driver",
  },
};
