import { useGetPermissions } from "@api/queries";
import { NAVIGATE_ROUTES, ROLES, ROUTES_RENDER_KEY } from "@common/Constants";
import { RoleType } from "@common/Types";
import { APP_PERMISSIONS } from "@configs";

type KeyType = keyof typeof ROUTES_RENDER_KEY;

/**
 * Custom hook to get filtered routes based on the provided role.
 *
 * @param {Role} role - The role of the user, which determines which permissions and routes are used.
 * @returns {Array} - The filtered routes based on the active permissions for the given role.
 */

export const useGetRoutes = (role: RoleType) => {
  let activePages = [];
  const isAdminDispatcher = role === ROLES.DISPATCHER;

  const { response = [], isLoading } = useGetPermissions({
    enabled: isAdminDispatcher,
  });

  if (isAdminDispatcher) {
    const activeKeys = Object.keys(ROUTES_RENDER_KEY).filter((key) =>
      response.some(
        (item) =>
          item.is_active && item.name === ROUTES_RENDER_KEY[key as KeyType],
      ),
    );

    activePages = activeKeys.map((key) => NAVIGATE_ROUTES[key as keyof typeof NAVIGATE_ROUTES]);
  } else {
    activePages = APP_PERMISSIONS.PERMISSIONS[role];
  }

  return { routes: activePages, isLoading };
};
