import { ROLES } from "@common/Constants";
import { RoleType } from "@common/Types";

export const roleName = (role: RoleType) => {
  switch (role) {
    case ROLES.RIDER:
      return "Клиент";
    case ROLES.DRIVER:
      return "Водитель";
    case ROLES.DISPATCHER:
      return "Администратор-диспетчер";
    case ROLES.PARKADMIN:
      return "Администратор таксопарка";
    case ROLES.CITYSUPERADMIN:
      return "Администратор города";
    case ROLES.SUPERADMIN:
      return "Суперадминистратор";
    default:
      return role;
  }
};
