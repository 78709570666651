import { endOfDay, format, isValid, parse, startOfDay } from "date-fns";
import { ru } from "date-fns/locale";

const options = {
  locale: ru,
};

export const formatDate = (
  date?: Date | string | null,
  formatType = "dd-MM-yyyy",
): string | null => {
  if (!date) return null;

  let parsedDate = new Date(date);

  if (typeof date === "string" && !isValid(new Date(date))) {
    parsedDate = parse(date, "dd-MM-yyyy", new Date());
  }

  return format(parsedDate, formatType, options);
};

export const formatDate1 = (
  date?: Date | string | null,
  formatType = "dd-MM-yyyy",
): string | null => {
  if (!date) return null;
  return format(new Date(date), formatType.toString(), options);
};

// Function to format date using date-fns
export function formatDateToMonth(date: Date) {
  return format(date, "d MMMM", { locale: ru });
}

// parse seconds to `${hours} ч. ${minutes} мин`
export const formatSecondsToHoursAndMinutes = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  if (hours === 0) {
    return `${minutes} мин`;
  }

  return `${hours} ч. ${minutes} мин`;
};

export const formatSecondsToTimeString = (totalSeconds: number) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const parts = [];
  if (hours > 0) parts.push(`${hours} ч.`);
  if (minutes > 0) parts.push(`${minutes} мин.`);
  if (seconds > 0) parts.push(`${seconds} сек.`);

  return parts.join(" ");
};

export const formatDateRangeISO = (
  dateString: string | undefined | null,
  isEndOfDay = false,
) => {
  if (!dateString) return;

  const parsedDate = parse(dateString, "dd-MM-yyyy", new Date());
  const finalDate = isEndOfDay ? endOfDay(parsedDate) : startOfDay(parsedDate);
  return finalDate.toISOString();
};
