import React from "react";

import { Col, Modal, Typography } from "@components/Elements";

type Values = {
  author?: string;
  comment?: string;
};

type Props = {
  onClose: () => void;
  open: boolean;
  title: string;
  values: Values;
};

export const ReasonModal: React.FC<Props> = ({
  open,
  onClose,
  title,
  values,
}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal title={title} opened={open} onClose={handleClose}>
      <Col className="pt-4">
        {values?.author && (
          <Typography color="neutral-900" variant="body-2-a">
            {values?.author}
          </Typography>
        )}
        <Col className="mt-2 p-3 rounded-sm w-full" bgColor="neutral-75">
          <Typography
            as="p"
            color="neutral-900"
            variant="body-1-a"
            className="line-clamp-4"
          >
            {values?.comment}
          </Typography>
        </Col>
      </Col>
    </Modal>
  );
};
