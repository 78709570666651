import { useField } from "formik";
import { isArray } from "radash";
import React, { Fragment, ReactNode, useState } from "react";

import { ChevronIcon } from "@assets/Icons";
import { ColorClassKey, CommonFieldParams } from "@common/Types";
import { SwitchFieldParams } from "@common/Types";
import { cn } from "@common/Utils";
import { Button, Flexbox, Row, Switch, Typography } from "@components/Elements";

type LocalFieldParams<T> = CommonFieldParams<T> & {
  onChange?: (value: number, field?: Exclude<keyof T, "">) => void;
  customValue?: boolean;
} & SwitchFieldParams<T>;

type Props<T> = {
  children?: ({ checked }: { checked: boolean | number }) => ReactNode;
  params: LocalFieldParams<T>[] | LocalFieldParams<T>;
  bgColor?: ColorClassKey;
  isEdit?: boolean;
  collapsible?: boolean;
  className?: string;
};

export const FormSwitchField = <T,>({
  children,
  params,
  isEdit = true,
  collapsible,
  bgColor = "neutral-50",
}: Props<T>) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const transformedParams = isArray(params) ? params : [params];

  const toggleCollapse = () => setIsCollapsed((prev) => !prev);

  return (
    <>
      {transformedParams.map((limit: LocalFieldParams<T>, index: number) => {
        const {
          name,
          field,
          Icon,
          className,
          customValue,
          show = true,
          onChange,
          switchClassName,
        } = limit;

        const [{ value }, , { setValue }] = useField(String(field));

        const currentValue = customValue ?? Number(value);

        const onSwitchChange = (value: boolean) => {
          const parsedValue = value ? 1 : 0;

          if (onChange) return onChange(parsedValue, field);
          setValue(parsedValue);
        };

        if (!show) return null;

        return (
          <Fragment key={`${String(field)}-${index}`}>
            <Flexbox
              justifyContent="justify-between"
              alignItems="items-center"
              bgColor={bgColor}
              className={cn("w-full py-2.5 px-4 rounded-lg", className)}
            >
              <Row alignItems="items-center" className="gap-[12px]">
                {Icon}
                <Typography color="neutral-800">{name}</Typography>
              </Row>

              <Row alignItems="items-center" className="gap-[14px]">
                <Switch
                  checked={currentValue}
                  disabled={!isEdit}
                  className={cn(
                    "disabled:checked:bg-brand-bg-500 disabled:cursor-not-allowed",
                    switchClassName,
                  )}
                  onChange={onSwitchChange}
                />

                {collapsible && (
                  <Button view="none" onClick={toggleCollapse}>
                    <ChevronIcon rotation={isCollapsed ? "bottom" : "top"} />
                  </Button>
                )}
              </Row>
            </Flexbox>

            {collapsible
              ? !isCollapsed
                ? children?.({ checked: currentValue })
                : null
              : children?.({ checked: currentValue })}
          </Fragment>
        );
      })}
    </>
  );
};
