import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { applicationService } from "@api/services";
import {
  LandingDriverApplicationRequest,
  LandingDriverApplicationResponse,
  QueryOptionsType,
} from "@common/Types";

export const useGetLandingDriverApplications = (
  params: LandingDriverApplicationRequest,
  options?: QueryOptionsType<LandingDriverApplicationResponse>,
) => {
  return useQuery<LandingDriverApplicationResponse>({
    queryKey: [QUERY_KEYS.LANDING_DRIVER_APPLICATIONS, params],
    queryFn: async () =>
      await applicationService.getLandingDriverApplications(params),
    ...options,
  });
};
