import React, { lazy, Suspense } from "react";

import { AppLogo } from "@assets/Icons";

import { TARGETS } from "../targets";

const componentsMap = {
  MotorDashboard: lazy(() =>
    import("@features/motor/index").then((module) => ({
      default: module.default,
    })),
  ),
  NaviDashboard: lazy(() =>
    import("@features/navi/index").then((module) => ({
      default: module.default,
    })),
  ),

  NaviHeader: lazy(() =>
    import("@components/Shared").then((module) => ({
      default: module.NaviHeader,
    })),
  ),

  MotorHeader: lazy(() =>
    import("@components/Shared").then((module) => ({
      default: module.MotorHeader,
    })),
  ),

  NaviFooter: lazy(() =>
    import("@components/Shared").then((module) => ({
      default: module.NaviFooter,
    })),
  ),

  MotorFooter: lazy(() =>
    import("@components/Shared").then((module) => ({
      default: module.MotorFooter,
    })),
  ),
};

const lazyComponent = (name: keyof typeof componentsMap) => {
  const Component = componentsMap[name];
  return () => (
    <Suspense>
      <Component />
    </Suspense>
  );
};

export const COMPONENT_CONFIGS = {
  [TARGETS.MOTOR]: {
    LOGO: AppLogo,
    LANDING_HEADER: lazyComponent("MotorHeader"),
    LANDING_FOOTER: lazyComponent("MotorFooter"),
    DASHBOARD: lazyComponent("MotorDashboard"),
  },
  [TARGETS.NAVI]: {
    LOGO: AppLogo,
    LANDING_HEADER: lazyComponent("NaviHeader"),
    LANDING_FOOTER: lazyComponent("NaviFooter"),
    DASHBOARD: lazyComponent("NaviDashboard"),
  },
};
