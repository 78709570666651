import { ReactNode } from "react";

import { CommonTypedFieldParams } from "@common/Types";
import { formatSecondsToTimeString } from "@common/Utils";

type Props<T> = {
  param: CommonTypedFieldParams<T>;
  currentValue: ReactNode;
  fieldValue: Exclude<keyof T, "">;
};

export const renderTextValue = <T>({
  param,
  currentValue,
  fieldValue,
}: Props<T>) => {
  switch (param.type) {
    case "text":
    case "number":
    case "select": {
      return currentValue != null ? currentValue : "-";
    }
    case "time": {
      return formatSecondsToTimeString(fieldValue as number);
    }
  }
};
