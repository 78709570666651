import { Popover } from "@mantine/core";
import EmojiPicker, { IEmojiPickerProps } from "emoji-picker-react";
import React, { useState } from "react";

import { EmojiPickerIcon } from "@assets/Icons";
import { Button } from "@components/Elements";

type Props = IEmojiPickerProps;

export const EmojiPopover = ({ onEmojiClick }: Props) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const handleEmojiPicker = () => {
    setShowEmojiPicker((prev) => !prev);
  };

  return (
    <Popover
      opened={showEmojiPicker}
      onClose={handleEmojiPicker}
      placement="start"
      position="top"
      target={
        <Button view="none" onClick={handleEmojiPicker} className="flex">
          <EmojiPickerIcon />
        </Button>
      }
      classNames={{
        body: "border-none shadow-none",
        popover: "bg-transparent",
        inner: "p-0",
      }}
    >
      <EmojiPicker onEmojiClick={onEmojiClick} />
    </Popover>
  );
};
