/**
 * Сервисы для statistics
 */

import { appConfig } from "@api/config";

const src = appConfig.api.dashboard;

export const statisticsPath = {
  rides_statistics: `${src}/statistics/rides/`,
  rider_statistics_cancellation: `${src}/rides/statistics/cancelled/`,
  rider_event_statistics: `${src}/statistics/events/`,
  driver_status_statistics_average_all: `${src}/drivers/status/statistics/average/all/`,
  driver_shift_statistics_average_all: `${src}/drivers/status/statistics/average/working-time/all/`,
};
