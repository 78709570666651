import { omit } from "radash";

import { httpClient } from "@common/Libs/axios";
import {
  CorporateClientCreateBalanceDetailType,
  CorporateClientDetailType,
  CorporateClientEmployeeType,
  CorporateClientsFinanceReportParams,
  CorporateClientsFinanceReportsType,
  CorporateClientsType,
  CorporateClientTransactionType,
  CorporateEmployeesParams,
  CreateCorporateClientEmployeeType,
  CreateCorporateClientType,
  CreateFinanceReportType,
  DownloadReportParams,
  IDType,
  TransactionParams,
} from "@common/Types";
import {
  buildDateRangeFiltersValues,
  buildDateRangeFromTextRange,
  removeEmptyFilterValues,
} from "@common/Utils";

import { corporateClientsPath } from "./paths";

export const corporateClientsService = {
  getCorporateClients: (skip: number, limit: number) =>
    httpClient.get<CorporateClientsType>(
      corporateClientsPath.corporate_clients,
      {
        params: { skip, limit },
      },
    ),
  createCorporateClients: (data: CreateCorporateClientType) =>
    httpClient.post<CorporateClientDetailType, CreateCorporateClientType>(
      corporateClientsPath.corporate_clients,
      data,
    ),
  getCorporateClientsDetail: (id: IDType) =>
    httpClient.get<CorporateClientDetailType>(
      corporateClientsPath.corporate_clients_detail(id),
    ),
  editCorporateClients: (id: IDType, data: CreateCorporateClientType) =>
    httpClient.put<CreateCorporateClientType, CreateCorporateClientType>(
      corporateClientsPath.corporate_clients_detail(id),
      data,
    ),

  createCorporateClientsEmployee: (
    id: IDType,
    data: CreateCorporateClientEmployeeType,
  ) =>
    httpClient.post<
      CreateCorporateClientEmployeeType,
      CreateCorporateClientEmployeeType
    >(corporateClientsPath.create_employee(id), data),

  getCorporateClientEmployees: ({
    id,
    limit,
    skip,
  }: CorporateEmployeesParams) =>
    httpClient.get<CorporateClientEmployeeType>(
      corporateClientsPath.corporate_client_employees(id),
      {
        params: { skip, limit },
      },
    ),

  createCorporateClientsOperation: (
    data: CorporateClientCreateBalanceDetailType,
    id: IDType,
  ) =>
    httpClient.post<
      CorporateClientCreateBalanceDetailType,
      CorporateClientCreateBalanceDetailType
    >(corporateClientsPath.operation(id), data),

  deleteCorporateClientsEmployee: (id: IDType, employeeId: IDType) =>
    httpClient.delete(
      corporateClientsPath.corporate_client_employee(id, employeeId),
    ),

  getCorporateClientsTransactions: ({
    skip,
    limit,
    id,
    filters,
  }: TransactionParams) => {
    const transFormedDateRange =
      buildDateRangeFromTextRange("select_range")(filters);

    const transformedFilters =
      buildDateRangeFiltersValues(transFormedDateRange);

    const filteredData = {
      ...omit(transformedFilters, ["start", "end"]),
      start_date: transformedFilters.start,
      end_date: transformedFilters.end,
    };

    return httpClient.get<CorporateClientTransactionType>(
      corporateClientsPath.corporate_clients_transactions(id),
      {
        params: { ...filteredData, skip, limit },
      },
    );
  },

  downloadCorporateClientsReports: ({ reportId }: DownloadReportParams) =>
    httpClient.get<{ url: string }>(
      corporateClientsPath.corporate_clients_download_reports(reportId),
      {
        params: {
          report_id: reportId,
          report_file_type: "xlsx",
        },
      },
    ),

  createCorporateClientsFinancialReport: (
    id: IDType,
    data: CreateFinanceReportType,
  ) =>
    httpClient.post<CreateFinanceReportType, CreateFinanceReportType>(
      corporateClientsPath.corporate_clients_reports(id),
      data,
    ),

  getCorporateClientsReports: ({
    id,
    limit,
    filters,
    skip,
  }: CorporateClientsFinanceReportParams) => {
    const filterValues = removeEmptyFilterValues(filters);

    return httpClient.get<CorporateClientsFinanceReportsType>(
      corporateClientsPath.corporate_clients_reports(id),
      {
        params: { ...filterValues, skip, limit },
      },
    );
  },
};
