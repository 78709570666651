import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

// Время задержки для одинаковых запросов
const SLEEP_TIME = 1200; // 1.2 секунды

// Очередь запросов
const requestTimestamps = new Map<string, number>();

// Функция для задержки выполнения
const sleep = (ms: number) =>
  new Promise<void>((resolve) => setTimeout(resolve, ms));

// Функция для генерации ключа
const generateKey = (config: AxiosRequestConfig): string => {
  const url = config.url || "";
  const method = config.method?.toUpperCase();
  return `${method}:${url}`;
};

// Очищаем очередь запросов, если URL изменился
let lastUrl = window.location.href;

// Кастомный адаптер
export const queueAdapter = async (
  config: AxiosRequestConfig,
): Promise<AxiosResponse> => {
  const defaultAdapter = axios.defaults.adapter;

  if (!defaultAdapter) {
    throw new Error("Axios adapter is not available.");
  }

  const key = generateKey(config);
  const currentUrl = window.location.href;

  // Если URL изменился — очищаем очередь запросов
  if (currentUrl !== lastUrl) {
    lastUrl = currentUrl;
    requestTimestamps.clear();
  }

  const prevTimestamp = requestTimestamps.get(key) || 0;
  const elapsedTime = Date.now() - prevTimestamp;
  const remainingWaitTime = Math.max(SLEEP_TIME - elapsedTime, 0);

  if (remainingWaitTime) {
    await sleep(remainingWaitTime);
    requestTimestamps.delete(key);
  }

  requestTimestamps.set(key, Date.now());
  return defaultAdapter(config).catch((err) => {
    requestTimestamps.delete(key);
    return Promise.reject(err);
  });
};
