import { useField } from "formik";
import React from "react";

import { CommonTypedFieldParams } from "@common/Types";

import { FormSwitchField } from "../form-switch-field";
import { Limits } from "../limits";

type Props<T> = {
  name: string;
  field?: Exclude<keyof T, "">;
  isEdit?: boolean;
  customValue?: boolean | number;
  onChange?: (value: number, field?: Exclude<keyof T, "">) => void;
  params: CommonTypedFieldParams<T>[] | CommonTypedFieldParams<T>;
};

export const FormExtendedSwitchField = <T,>({
  name,
  field,
  isEdit = false,
  customValue,
  params,
  onChange,
}: Props<T>) => {
  const [fieldProps] = useField(String(field));

  const currentValue = customValue ?? fieldProps.value;

  return (
    <div className="bg-neutral-bg-50 rounded-lg">
      <FormSwitchField
        bgColor="transparent"
        isEdit={isEdit}
        params={{
          name: name,
          customValue: currentValue,
          field: field,
          onChange: onChange,
        }}
      />

      {!!currentValue && <Limits isEdit={isEdit} params={params} />}
    </div>
  );
};
