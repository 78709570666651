import { MultiSelectView, MultiSelectViewStyles } from "./types";

const size = {
  default: "py-[8px] px-[14px]",
};

const font = {
  color: "text-neutral-text-800",
  placeholder: "placeholder-neutral-text-300",
  size: "text-body-1-a",
};

const common = {
  focus: "focus-within:border-brand-border-500",
  focusError: "focus:border-negative-border-500",
  input: "shadow-none border",
};

const disabledStyles =
  "disabled:placeholder:text-neutral-text-200 disabled:text-neutral-text-200 disabled:opacity-100 disabled:bg-neutral-bg-50";
const baseInput = `${common.input} ${font.color} ${font.placeholder} ${font.size} ${size.default} ${disabledStyles}`;

const classes = {
  inputDefault: `border-neutral-border-300 ${common.focus} ${baseInput}`,
  inputNoBorder: `border-transparent bg-neutral-bg-50 ${common.focus} ${baseInput}`,
  inputError: `border-negative-border-500 ${common.focusError} ${baseInput}`,
  searchInput: `ring-0 ring-transparent m-0 ${font.placeholder} ${font.size}`,
  label: "text-caption-b text-neutral-text-800 font-medium",
  icon: "mr-[6px]",
  error: "text-caption-b text-negative-text-500",
  values: "p-0 m-0 min-h-[0px]",
  value: "ml-0 my-[1px]",
};

export const MultiSelectClasses: Record<
  MultiSelectView,
  MultiSelectViewStyles
> = {
  default: {
    input: classes.inputDefault,
    searchInput: classes.searchInput,
    label: classes.label,
    error: classes.error,
    icon: classes.icon,
    values: classes.values,
    value: classes.value,
  },
  "no-border": {
    input: classes.inputNoBorder,
    searchInput: classes.searchInput,
    label: classes.label,
    error: classes.error,
    icon: classes.icon,
    values: classes.values,
    value: classes.value,
  },
  error: {
    input: classes.inputError,
    searchInput: classes.searchInput,
    label: classes.label,
    error: classes.error,
    icon: classes.icon,
    values: classes.values,
    value: classes.value,
  },
};
