import React from "react";

import { Button, Grid, Row } from "@components/Elements";

import { hasComponent } from "../common/utils";
import { RightGroup } from "./right-group";

const MAX_VISIBLE_FILTERS = 5;

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

export const DesktopFilter = ({ children }: Props) => {
  const [showMore, setShowMore] = React.useState(false);

  const { component: rightGroup, remainingChildren } = React.useMemo(() => {
    return hasComponent(children, RightGroup);
  }, [children]);

  const slicedChildren = React.useMemo(() => {
    if (!showMore) {
      return remainingChildren.slice(0, MAX_VISIBLE_FILTERS);
    }

    return remainingChildren;
  }, [showMore, remainingChildren]);

  const showButton = remainingChildren.length > MAX_VISIBLE_FILTERS;

  return (
    <Row className="rounded-[8px] p-2 w-full" bgColor="neutral-0">
      <Grid
        columns={showButton ? "auto-fit" : "auto-fill"}
        minColumnWidth="240px"
        className="gap-2 w-full"
      >
        {slicedChildren}
        {showButton && (
          <Button
            type="button"
            view="transparent"
            size={"md"}
            onClick={() => setShowMore(!showMore)}
            className="w-[62px] h-[40px]"
          >
            {showMore ? "Скрыть" : "Еще"}
          </Button>
        )}
      </Grid>
      {rightGroup}
    </Row>
  );
};
