import React from "react";

export const CoinsIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.249 5C14.25 3.895 12.01 3 9.25 3C6.49 3 4.252 3.896 4.25 5C4.25 6.105 6.488 7 9.25 7C12.012 7 14.25 6.105 14.25 5"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 5V12.76"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.25098 9C4.25098 10.105 6.48898 11 9.25098 11C12.013 11 14.251 10.105 14.251 9"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9322 13.3182C21.6892 15.0752 21.6892 17.9252 19.9322 19.6822C18.1752 21.4392 15.3252 21.4392 13.5682 19.6822C11.8112 17.9252 11.8112 15.0752 13.5682 13.3182C15.3252 11.5612 18.1752 11.5612 19.9322 13.3182"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.755 14.425C11.853 14.78 10.616 15 9.24998 15C6.48898 15 4.25098 14.105 4.25098 13"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.695 18.444C11.798 18.787 10.589 19 9.25 19C6.489 19 4.251 18.105 4.25 17V5"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
