import { TransactionFilterType } from "@common/Types";

// PAYMENT
export const PAYMENT_FILTERS_NAVI = [
  { label: "Картой", value: "payler" },
  { label: "Мбанк", value: "mbank" },
];

export const BANK_PROVIDER_FILTER_TYPE_NAVI = [
  { label: "Mbank", value: "mbank" },
];

export const PAYMENT_PROVIDER_NAVI_LABEL = {
  payler: "Картой",
  mbank: "Мбанк",
};

export const TRANSACTION_TYPES_NAVI = [
  {
    value: TransactionFilterType.MBANK_WITHDRAW,
    label: "Вывод средств - MBank",
    group: "Списания",
  },
  {
    value: TransactionFilterType.MBANK_WITHDRAW_COMMISSION,
    label: "Комиссия за вывод - MBank",
    group: "Списания",
  },
];
