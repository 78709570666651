import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { statisticsService } from "@api/services";
import { StatisticsFiltersType } from "@common/Types";

type Params = {
  filters: StatisticsFiltersType;
  enabled?: boolean;
};

export const useGetRidesStatisticsCancellation = ({
  enabled = true,
  ...rest
}: Params) => {
  return useQuery({
    queryKey: [QUERY_KEYS.RIDES_STATISTICS_CANCELLATION, rest],
    queryFn: () => statisticsService.getRidesStatisticsCancellation(rest),
    enabled,
  });
};
